import { generateAiImage, createSound, updateSound, createTag, createGenre, createInstrument } from '../../../api/APIManager';
import { STEPS } from '../UploadUtils';

export const useUploadHandlers = ({
    soundId, // Add soundId to the parameters
    formState,
    optionsState,
    handleFileAnalysis,
    setCurrentPlayingSound,
    setGlobalIsPlaying,
    navigate
}) => {
    const handleFileSelection = async (selectedFile) => {
        formState.setFile(selectedFile);
        if (selectedFile) {
            formState.setFileName(selectedFile.name);
            const fileName = selectedFile.name.split('.').slice(0, -1).join('.');
            formState.setName(fileName);
            const previewUrl = URL.createObjectURL(selectedFile);
            formState.setAudioPreview(previewUrl);

            handleFileAnalysis(selectedFile);
            formState.setStep(STEPS.IMAGE);
        }
    };

    const handleGenerateAiImage = async () => {
        if (!formState.name) return;
        
        formState.setIsGeneratingImage(true);
        try {
            const cleanName = formState.name.replace(/[^\w\s]/gi, '').trim();
            const imageBlob = await generateAiImage(cleanName);
            
            const timestamp = new Date().getTime();
            const aiGeneratedFile = new File([imageBlob], `cover-${timestamp}.jpg`, { type: 'image/jpeg' });
            
            if (formState.imagePreview) {
                URL.revokeObjectURL(formState.imagePreview);
            }
            
            formState.setImage(aiGeneratedFile);
            formState.setImagePreview(URL.createObjectURL(imageBlob));
        } catch (error) {
            console.error('Error generating image:', error);
            alert('Failed to generate image. Please try again or upload your own image.');
        } finally {
            formState.setIsGeneratingImage(false);
        }
    };

    const togglePlayPause = () => {
        if (formState.audioPreview) {
            if (formState.isLocalPlaying) {
                setGlobalIsPlaying(false);
            } else {
                setCurrentPlayingSound({
                    id: 'preview',
                    audio_preview: formState.audioPreview,
                    name: formState.name || 'Preview'
                });
                setGlobalIsPlaying(true);
            }
            formState.setIsLocalPlaying(!formState.isLocalPlaying);
        }
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!formState.contentOwnership) {
            alert("You must declare content ownership before uploading.");
            return;
        }

        formState.setIsUploading(true);

        try {
            const updatedTags = await Promise.all(optionsState.selectedTags.map(async (tag) => {
                if (!tag.id) {
                    const newTag = await createTag({ name: tag.name.toLowerCase() });
                    return { id: newTag.id, name: newTag.name };
                }
                return { ...tag, name: tag.name.toLowerCase() };
            }));

            const updatedGenres = await Promise.all(optionsState.selectedGenres.map(async (genre) => {
                if (!genre.id) {
                    const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                    return { id: newGenre.id, name: newGenre.name };
                }
                return { ...genre, name: genre.name.toLowerCase() };
            }));

            const updatedInstruments = await Promise.all(optionsState.selectedInstruments.map(async (instrument) => {
                if (!instrument.id) {
                    const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                    return { id: newInstrument.id, name: newInstrument.name };
                }
                return { ...instrument, name: instrument.name.toLowerCase() };
            }));

            const soundData = {
                file: formState.file,
                fileName: formState.fileName,
                image: formState.image,
                name: formState.name,
                description: formState.description,
                tags: updatedTags,
                genres: updatedGenres,
                instruments: updatedInstruments,
                typeId: formState.typeId,
                bpm: formState.bpm,
                key: formState.key,
                costInCredits: formState.costInCredits,
                status: formState.status,
                license: optionsState.selectedLicense,
            };

            if (soundId) {
                await updateSound(soundId, soundData);
            } else {
                await createSound(soundData);
            }

            navigate('/');
            window.location.reload();
        } catch (error) {
            console.error(`Error ${soundId ? 'updating' : 'uploading'} sound:`, error);
        } finally {
            formState.setIsUploading(false);
        }
    };

    const handleStepNavigation = {
        next: () => formState.setStep(prevStep => prevStep + 1),
        prev: () => formState.setStep(prevStep => prevStep - 1),
        isStepCompleted: (stepIndex) => {
            switch (stepIndex) {
                case STEPS.SOUND_FILE:
                    return !!formState.file;
                case STEPS.IMAGE:
                    return true;
                case STEPS.VISIBILITY:
                    return !!formState.status;
                case STEPS.METADATA:
                    return !!formState.name;
                case STEPS.CLASSIFICATIONS:
                    return !!formState.typeId;
                case STEPS.REVIEW:
                    return true;
                default:
                    return false;
            }
        },
        canAccessStep: (targetStep) => {
            if (soundId) return true;

            switch (targetStep) {
                case STEPS.SOUND_FILE:
                    return true;
                case STEPS.IMAGE:
                case STEPS.VISIBILITY:
                case STEPS.METADATA:
                case STEPS.CLASSIFICATIONS:
                case STEPS.REVIEW:
                    return !!formState.file;
                default:
                    return false;
            }
        },
        goToStep: (targetStep) => {
            if (handleStepNavigation.canAccessStep(targetStep)) {
                formState.setStep(targetStep);
            }
        }
    };

    return {
        handleFileSelection,
        handleGenerateAiImage,
        togglePlayPause,
        handleSubmit,
        handleStepNavigation
    };
};
