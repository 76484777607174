import React from 'react';

const RevenueChart = ({ data }) => {
  const [width] = "100%";
  const maxRevenue = Math.max(...data.map(d => d.revenue));
  const chartHeight = 200;

  // Determine how many months to show based on screen width
  const monthsToShow = width < 640 ? 6 : data.length;
  const displayData = data.slice(-monthsToShow);

  return (
    <div className="w-full h-80 sm:h-64 bg-bg-secondary relative rounded-lg overflow-hidden p-4">
      <div className="absolute top-4 left-4 right-4 bottom-8 flex items-end">
        {displayData.map((d, i) => {
          const height = (d.revenue / maxRevenue) * (chartHeight - 40);
          return (
            <div
              key={i}
              className="relative h-full flex-1 px-1 sm:px-0"
            >
              <div 
                className="absolute bottom-0 w-full sm:w-4/5 mx-auto rounded-t-md transition-all duration-300 ease-in-out hover:brightness-110"
                style={{
                  height: `${height}px`,
                  background: `linear-gradient(to top, rgba(33, 212, 253, 0.7), rgba(183, 33, 255, 0.7))`,
                }}
              >
                <div className="absolute top-0 left-0 right-0 -mt-6 text-center text-xs sm:text-sm font-semibold text-text-primary">
                  ${d.revenue.toLocaleString()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 left-4 right-4 flex justify-between">
        {displayData.map((d, i) => (
          <div key={i} className="text-center text-xs sm:text-sm font-medium text-text-secondary w-full px-1 sm:px-0">
            {d.month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevenueChart;