import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import SpinnerFull from './SpinnerFull';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  
  if (isLoading) {
    return <SpinnerFull />;
  }
  
  return isAuthenticated ? children : <Navigate to="/landing" />;
};

export default PrivateRoute