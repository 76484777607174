import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { isJUCE } from '../context/JUCE';

const NavItem = ({ icon, label, to, onClick }) => (
  <Link 
    to={to} 
    onClick={onClick}
    className="flex items-center space-x-3 text-white hover:text-white/90 transition-all duration-200 rounded-lg p-2 hover:bg-white/5 w-full group"
  >
    <div className="w-5 h-5 opacity-70 group-hover:opacity-100">
      {icon}
    </div>
    <span className="text-sm font-medium">
      {label}
    </span>
  </Link>
);

const NavSection = ({ title, children }) => (
  <div className="space-y-2">
    {title && (
      <h3 className="text-xs font-semibold uppercase tracking-wider text-white/50 px-2">
        {title}
      </h3>
    )}
    <div className="space-y-1">
      {children}
    </div>
  </div>
);

export const Navigation = ({ isOpen, onClose }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isOpen) onClose();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, onClose]);

  const navigationItems = [
    {
      section: null,
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z"/>
          </svg>,
          label: 'Explore',
          to: '/'
        }
      ]
    },
    {
      section: 'Library',
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Purchased',
          to: '/purchased'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
          </svg>,
          label: 'Uploaded',
          to: '/uploaded'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>,
          label: 'Liked',
          to: '/likes'
        }
      ]
    },
    {
      section: 'Account',
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
          </svg>,
          label: 'Profile',
          to: '/profile/me'
        }
      ]
    }
  ];

  if (user?.verified) {
    navigationItems.push({
      section: 'Creator Tools',
      items: [{
        icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
          <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
        </svg>,
        label: 'Batch Upload',
        to: '/batch-upload'
      }]
    });
  }

  if (user?.admin) {
    navigationItems.push({
      section: 'Admin',
      items: [
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
          </svg>,
          label: 'Batch Edit',
          to: '/batch-edit'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
          </svg>,
          label: 'Manage Tags',
          to: '/admin/tags'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Manage Instruments',
          to: '/admin/instruments'
        },
        {
          icon: <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>,
          label: 'Manage Genres',
          to: '/admin/genres'
        }
      ]
    });
  }
  
  const navClasses = `
    fixed top-0 left-0 h-full w-72 backdrop-blur-xl
    border-r border-white/10 shadow-2xl md:shadow-none
    transition-transform duration-300 ease-in-out
    ${isOpen ? 'translate-x-0' : '-translate-x-full'}
    md:translate-x-0 md:relative md:w-64 z-50
    flex flex-col
  `;

  return (
    <>
      <nav className={navClasses}>
        {/* Main scrollable navigation area */}
        <div className="flex-1 overflow-hidden flex flex-col">
          {/* Scrollable container with padding */}
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {navigationItems.map((group, idx) => (
              <NavSection key={idx} title={group.section}>
                {group.items.map((item, itemIdx) => (
                  <NavItem
                    key={itemIdx}
                    to={item.to}
                    icon={item.icon}
                    label={item.label}
                    onClick={onClose}
                  />
                ))}
              </NavSection>
            ))}
          </div>
          
          {/* Static bottom section */}
          <div className="p-6 border-t border-white/10 bg-bg-primary/95 backdrop-blur-xl">
            <div className="space-y-6">
              <button
                onClick={() => window.location.href = '/upload'}
                className="w-full bg-gradient-to-r from-accent-end to-accent-end hover:opacity-90
                  text-white px-4 py-2.5 rounded-lg font-medium
                  transition-all duration-200 text-sm"
              >
                Upload Sounds
              </button>

              {!isJUCE() && (
                <div className="space-y-3">
                  <h3 className="text-xs font-semibold uppercase tracking-wider text-white/50 text-center">
                    Download Plugin
                  </h3>
                  <div className="grid grid-cols-2 gap-2">
                    <button
                      onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Win%20Installer.exe"}
                      className="flex flex-col items-center justify-center p-3 rounded-lg
                        bg-bg-primary/20 hover:bg-bg-primary/30 border border-white/10
                        transition-colors duration-200 text-white"
                    >
                      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
                      </svg>
                      <span className="text-xs mt-1 font-medium">Windows</span>
                    </button>

                    <button
                      onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Mac%20Installer_signed.pkg"}
                      className="flex flex-col items-center justify-center p-3 rounded-lg
                        bg-bg-primary/20 hover:bg-bg-primary/30 border border-white/10
                        transition-colors duration-200 text-white"
                    >
                      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                      </svg>
                      <span className="text-xs mt-1 font-medium">macOS</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm md:hidden z-40"
          onClick={onClose}
        />
      )}
    </>
  );
};

export default Navigation;