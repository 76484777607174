import React, { useState, useEffect } from 'react';
import { acceptLicense, reportSound } from '../../api/APIManager';
import { CustomDialog } from '../CustomComponents';
import Spinner from '../Spinner';

export const SoundCardDialogs = ({
  sound,
  isLicenseModalOpen,
  setIsLicenseModalOpen,
  isReportDialogOpen,
  setIsReportDialogOpen,
  onLicenseSuccess
}) => {
  const [isAcceptingLicense, setIsAcceptingLicense] = useState(false);
  const [canAcceptLicense, setCanAcceptLicense] = useState(false);
  const [acceptTimer, setAcceptTimer] = useState(null);
  const [selectedReportReason, setSelectedReportReason] = useState('');
  const [isSubmittingReport, setIsSubmittingReport] = useState(false);

  useEffect(() => {
    // Clean up timer when modal closes
    if (!isLicenseModalOpen && acceptTimer) {
      clearTimeout(acceptTimer);
      setAcceptTimer(null);
    }

    // Set new timer when modal opens
    if (isLicenseModalOpen) {
      setCanAcceptLicense(false);
      if (acceptTimer) {
        clearTimeout(acceptTimer);
      }
      const timer = setTimeout(() => {
        setCanAcceptLicense(true);
      }, 3000);
      setAcceptTimer(timer);
    }
  }, [isLicenseModalOpen]);

  const handleAcceptLicense = async () => {
    if (!sound.license || !sound.license.id) return;

    setIsAcceptingLicense(true);
    try {
      await acceptLicense(sound.license.id);
      const updatedSound = {
        ...sound,
        has_accepted_required_license: true
      };
      onLicenseSuccess(updatedSound);
      setIsLicenseModalOpen(false);
    } catch (error) {
      console.error('Error accepting license:', error);
      alert('Failed to accept license. Please try again.');
    } finally {
      setIsAcceptingLicense(false);
    }
  };

  const handleReport = async () => {
    if (!selectedReportReason) return;
    
    setIsSubmittingReport(true);
    try {
      const success = await reportSound(sound.id, selectedReportReason);
      if (success) {
        setIsReportDialogOpen(false);
      }
    } catch (error) {
      console.error('Error reporting sound:', error);
    } finally {
      setIsSubmittingReport(false);
      setSelectedReportReason('');
    }
  };

  return (
    <>
      <CustomDialog 
        isOpen={isLicenseModalOpen} 
        onClose={(e) => {
          e?.stopPropagation();
          setIsLicenseModalOpen(false);
        }}
        title={`${sound.license?.name || 'License'} Agreement`}
      >
        <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
          <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
            <iframe
              src={sound.license?.url}
              className="w-full h-full border-0"
              title="License Agreement"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsLicenseModalOpen(false);
              }}
              className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 text-text-primary"
            >
              Cancel
            </button>
            {!sound.has_accepted_required_license && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleAcceptLicense();
                }}
                disabled={!canAcceptLicense || isAcceptingLicense}
                className={`
                  px-4 py-2 rounded bg-accent-start text-text-primary
                  ${(!canAcceptLicense || isAcceptingLicense) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
                  transition-colors duration-200
                `}
              >
                {isAcceptingLicense ? (
                  <Spinner />
                ) : !canAcceptLicense ? (
                  'Please wait...'
                ) : (
                  'Accept'
                )}
              </button>
            )}
          </div>
        </div>
      </CustomDialog>

      <CustomDialog 
        isOpen={isReportDialogOpen} 
        onClose={() => setIsReportDialogOpen(false)}
        title="Report Sound"
      >
        <div className="space-y-4">
          <p className="text-text-secondary">Please select a reason for reporting this sound</p>
          {['hate', 'copyright infringement', 'other'].map((reason) => (
            <button
              key={reason}
              onClick={() => setSelectedReportReason(reason)}
              className={`
                w-full px-4 py-2 text-left rounded
                ${selectedReportReason === reason ? 'bg-accent-start text-white' : 'hover:bg-gray-700'}
                transition-colors duration-200
              `}
            >
              {reason.charAt(0).toUpperCase() + reason.slice(1)}
            </button>
          ))}
          <div className="flex justify-end space-x-2 pt-4">
            <button
              onClick={() => setIsReportDialogOpen(false)}
              className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleReport}
              disabled={!selectedReportReason || isSubmittingReport}
              className={`
                px-4 py-2 rounded bg-accent-start text-white
                ${(!selectedReportReason || isSubmittingReport) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
                transition-colors duration-200
              `}
            >
              {isSubmittingReport ? <Spinner /> : 'Submit'}
            </button>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default SoundCardDialogs;