import React from 'react';
import logo from '../assets/logo.png';

export const Logo = () => (
    <div className="flex items-center relative">
      <img src={logo} alt="Logo" className="h-10" />
      <span className="absolute -top-0.5 -right-7 text-[8px] border border-current px-1 rounded-full text-text-primary uppercase tracking-wider">Beta</span>
    </div>
);

export default Logo;