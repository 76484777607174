import mitt from 'mitt';

// Create a global event emitter
export const JUCEReceive = mitt();

// Check if the app is running inside JUCE
export const isJUCE = () => {
  return window.__JUCE__ && window.__JUCE__.backend;
};

export const JUCESend = async (eventName, eventData = '') => {
  if (isJUCE()) {
    // Check if eventData is an object and convert to a JSON string
    const dataToSend = typeof eventData === 'object' ? JSON.stringify(eventData) : eventData;

    // Emit an event to JUCE with the stringified eventData
    window.__JUCE__.backend.emitEvent("toJUCE", {
      eventName,
      eventData: dataToSend
    });
  } else {
    console.log('Not running inside JUCE, ignoring sendJUCEMessage');
  }
};

// Receive from JUCE
export const toWeb = (eventName, eventData) => {
  JUCEReceive.emit('JUCEReceive', { eventName, eventData });
};

// Ensure toWeb is exposed globally for JUCE
window.toWeb = toWeb;