import React, { useState, useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { LucidePlay, LucideSquare, LucideUpload, LucideX } from 'lucide-react';
import { VALID_KEYS, VALID_STATUS } from './constants';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';

const BatchUploadTable = ({
    files,
    setFiles,
    soundTypes,
    availableTags,
    availableGenres,
    availableInstruments,
    licenses,
    createSound,
    createTag,
    createGenre,
    createInstrument,
    setErrorMessage,
    editMode = false,
    handleCancelUpload,
    setCurrentPlayingSound, 
    setIsPlaying,
    currentPlayingSound,
    isPlaying
}) => {
    const gridRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const [uploadCancelled, setUploadCancelled] = useState(false);
    const [batchEditRow, setBatchEditRow] = useState({
        id: 'batch-edit',
        name: '',
        description: '',
        tags: [],
        genres: [],
        instruments: [],
        typeId: '',
        bpm: '',
        key: '',
        status: '',
        costInCredits: '',
        license: '',
        uploadStatus: 'batch-edit'
    });

    const createOptions = (items, valueKey = 'id', labelKey = 'name') => {
        return items.map(item => ({
            id: item[valueKey] || item,
            name: item[labelKey] || item
        }));
    };

    const handleBatchEdit = (field, newValue) => {
        setBatchEditRow(prev => ({ ...prev, [field]: newValue }));
        
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if (selectedNodes.length > 0) {
            setFiles(prevFiles => prevFiles.map(file => {
                if (selectedNodes.some(node => node.data.id === file.id)) {
                    return { ...file, [field]: newValue };
                }
                return file;
            }));

            // Refresh the grid to show updated values
            gridRef.current.api.refreshCells({
                force: true,
                rowNodes: selectedNodes
            });
        }
    };

    const handleIndividualEdit = (id, field, newValue) => {
        setFiles(prevFiles => prevFiles.map(file => {
            if (file.id === id) {
                return { ...file, [field]: newValue };
            }
            return file;
        }));

        // Select the row that was just edited
        const node = gridRef.current.api.getRowNode(id);
        if (node && !node.isSelected()) {
            node.setSelected(true);
        }

        // Refresh the specific row
        gridRef.current.api.refreshCells({
            force: true,
            rowNodes: [node]
        });
    };

    const handleValueChange = (params, newValue, field) => {
        if (params.data.id === 'batch-edit') {
            handleBatchEdit(field, newValue);
        } else {
            handleIndividualEdit(params.data.id, field, newValue);
        }
    };

    const onGridReady = (params) => {
        if (!editMode) {
            params.api.selectAll();
        }
        // Add event listener for row selection
        params.api.addEventListener('rowSelected', (event) => {
            if (event.node.data.id === 'batch-edit') {
                event.node.setSelected(false);
            }
        });
    };

    const AudioPreviewRenderer = ({ data }) => {
        if (data.id === 'batch-edit') return null;

        const isCurrentlyPlaying = currentPlayingSound?.id === data.id && isPlaying;
        
        const handlePlayPause = (e) => {
            e.stopPropagation();
            
            if (currentPlayingSound?.id === data.id) {
                setIsPlaying(!isPlaying);
            } else {
                const soundObj = {
                    id: data.id,
                    name: data.name,
                    audio_preview: data.preview,
                    image: data.imagePreview
                };
                setCurrentPlayingSound(soundObj);
                setIsPlaying(true);
            }
        };

        return (
            <div className="flex items-center gap-2">
                <button
                    onClick={handlePlayPause}
                    className="p-1 hover:bg-accent-start/20 rounded"
                >
                    {isCurrentlyPlaying ? <LucideSquare size={16} /> : <LucidePlay size={16} />}
                </button>
            </div>
        );
    };

    const ImageRenderer = ({ data }) => {
        const handleImageChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (data.id === 'batch-edit') {
                        // For batch edit, apply to all selected rows
                        const selectedNodes = gridRef.current.api.getSelectedNodes();
                        selectedNodes.forEach(node => {
                            handleIndividualEdit(node.data.id, 'image', file);
                            handleIndividualEdit(node.data.id, 'imagePreview', reader.result);
                        });
                    } else {
                        handleIndividualEdit(data.id, 'image', file);
                        handleIndividualEdit(data.id, 'imagePreview', reader.result);
                    }
                };
                reader.readAsDataURL(file);
            }
        };

        if (data.id === 'batch-edit') {
            return (
                <div className="flex items-center justify-center h-14">
                    <button 
                        onClick={() => document.getElementById('batch-image-upload').click()}
                        className="p-2 bg-accent-end hover:bg-accent-start/80 rounded flex items-center gap-2"
                    >
                        <LucideUpload size={16} />
                    </button>
                    <input
                        id="batch-image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="hidden"
                    />
                </div>
            );
        }

        return (
            <div 
                className="w-full h-14 flex items-center justify-center cursor-pointer relative group"
                onClick={() => document.getElementById(`image-upload-${data.id}`).click()}
            >
                {data.imagePreview ? (
                    <>
                        <img 
                            src={data.imagePreview} 
                            alt="Preview" 
                            className="max-h-14 w-auto object-contain"
                        />
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity">
                            <LucideUpload size={16} />
                        </div>
                    </>
                ) : (
                    <button className="p-2 bg-accent-end hover:bg-accent-start/80 rounded">
                        <LucideUpload size={16} />
                    </button>
                )}
                <input
                    id={`image-upload-${data.id}`}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                />
            </div>
        );
    };


    const StatusRenderer = ({ data }) => {
        if (data.id === 'batch-edit') {
            return <div className="text-accent-end font-medium"></div>;
        }

        const getStatusColor = (status) => {
            if (data.isAnalyzing) return 'text-blue-500';
            switch (status) {
                case 'success': return 'text-green-500';
                case 'error': return 'text-red-500';
                case 'uploading': return 'text-blue-500';
                case 'cancelled': return 'text-yellow-500';
                default: return 'text-gray-500';
            }
        };

        return (
            <div className={`flex items-center gap-2 ${getStatusColor(data.uploadStatus)}`}>
                {data.uploadStatus === 'error' && (
                    <button
                        onClick={() => handleRetry(data.id)}
                        className="p-1 hover:bg-accent-start/20 rounded"
                    >
                        <LucideUpload size={16} />
                    </button>
                )}
                {data.uploadStatus === 'uploading' && handleCancelUpload && (
                    <button
                        onClick={handleCancelUpload}
                        className="p-1 hover:bg-red-500/20 rounded"
                    >
                        <LucideX size={16} />
                    </button>
                )}
                <span className="capitalize">
                    {data.isAnalyzing ? 'Analyzing...' : data.uploadStatus}
                </span>
            </div>
        );
    };

    const getRowStyle = (params) => {
        if (params.node.data.id === 'batch-edit') {
            return { backgroundColor: 'transparent' };
        }
        // Subtract 1 from the index to account for batch edit row
        const actualIndex = params.rowIndex - 1;
        return { 
            backgroundColor: actualIndex % 2 === 0 ? 'transparent' : 'rgba(26, 26, 26, 0.95)'
        };
    };

    const columnDefs = useMemo(() => [
        {
            headerName: '',
            field: 'selected',
            width: 40,
            checkboxSelection: params => params.data.id !== 'batch-edit',
            headerCheckboxSelection: true,
            pinned: 'left'
        },
        {
            headerName: 'Name',
            field: 'name',
            width: 200,
            pinned: 'left',
            cellRenderer: params => (
                <input
                    type="text"
                    value={params.value || ''}
                    onChange={(e) => handleValueChange(params, e.target.value, 'name')}
                    className="w-full bg-transparent border-none focus:outline-none"
                    placeholder={params.data.id === 'batch-edit' ? 'Bulk Edit' : ''}
                />
            )
        },
        {
            headerName: 'Audio',
            field: 'preview',
            width: 44,
            cellRenderer: AudioPreviewRenderer,
            pinned: 'left',
            sortable: false,
            filter: false
        },
        {
            headerName: 'Upload Status',
            field: 'uploadStatus',
            width: 120,
            cellRenderer: StatusRenderer,
            pinned: 'left'
        },
       // Previous column definitions remain the same until image column...

       {
        headerName: 'Image *',
        field: 'image',
        width: 120,
        cellRenderer: ImageRenderer,
        sortable: false,
        filter: false,
        cellStyle: params => {
            if (params.data.id === 'batch-edit') return {};
            // Only show error background, no default background
            if (!params.data.image && !params.data.imagePreview) {
                return { backgroundColor: 'rgba(220, 38, 38, 0.1)' };
            }
            return {};
        }
    },
    {
        headerName: 'Type *',
        field: 'typeId',
        width: 170,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={soundTypes}
                    value={params.value ? [soundTypes.find(type => type.id === params.value)] : []}
                    onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'typeId')}
                    placeholder={params.data.id === 'batch-edit' ? 'Type' : 'Type *'}
                    isMulti={false}
                    className="text-sm"
                />
            </div>
        ),
        cellStyle: params => {
            if (!params.value && params.data.id !== 'batch-edit') {
                return { backgroundColor: 'rgba(220, 38, 38, 0.1)' };
            }
            return {};
        }
    },
    {
        headerName: 'License *',
        field: 'license',
        width: 190,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={licenses}
                    value={params.value ? [licenses.find(license => license.id === params.value)] : []}
                    onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'license')}
                    placeholder={params.data.id === 'batch-edit' ? 'License' : 'Select license *'}
                    isMulti={false}
                    className="text-sm"
                />
            </div>
        ),
        cellStyle: params => {
            if (!params.value && params.data.id !== 'batch-edit') {
                return { backgroundColor: 'rgba(220, 38, 38, 0.1)' };
            }
            return {};
        }
    },
    {
        headerName: 'Cost',
        field: 'costInCredits',
        width: 100,
        cellRenderer: params => (
            <input
                type="number"
                value={params.value || ''}
                onChange={(e) => handleValueChange(params, e.target.value, 'costInCredits')}
                className="w-full bg-transparent border-none focus:outline-none"
                placeholder={params.data.id === 'batch-edit' ? 'Cost' : ''}
            />
        )
    },
    {
        headerName: 'BPM',
        field: 'bpm',
        width: 100,
        cellRenderer: params => (
            <input
                type="number"
                value={params.value || ''}
                onChange={(e) => handleValueChange(params, e.target.value, 'bpm')}
                className="w-full bg-transparent border-none focus:outline-none"
                placeholder={params.data.id === 'batch-edit' ? 'BPM' : ''}
            />
        )
    },
    {
        headerName: 'Key',
        field: 'key',
        width: 170,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={createOptions(VALID_KEYS)}
                    value={params.value ? [{ id: params.value, name: params.value }] : []}
                    onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'key')}
                    placeholder={params.data.id === 'batch-edit' ? 'Key' : 'Key'}
                    isMulti={false}
                    className="text-sm"
                />
            </div>
        )
    },
    {
        headerName: 'Genres',
        field: 'genres',
        width: 300,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={availableGenres}
                    value={params.value || []}
                    onChange={(newValue) => handleValueChange(params, newValue, 'genres')}
                    placeholder={params.data.id === 'batch-edit' ? 'Genres' : 'Genres'}
                    isMulti={true}
                    className="text-sm"
                />
            </div>
        )
    },
    {
        headerName: 'Instruments',
        field: 'instruments',
        width: 300,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={availableInstruments}
                    value={params.value || []}
                    onChange={(newValue) => handleValueChange(params, newValue, 'instruments')}
                    placeholder={params.data.id === 'batch-edit' ? 'Instruments' : 'Instruments'}
                    isMulti={true}
                    className="text-sm"
                />
            </div>
        )
    },
    {
        headerName: 'Tags',
        field: 'tags',
        width: 300,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={availableTags}
                    value={params.value || []}
                    onChange={(newValue) => handleValueChange(params, newValue, 'tags')}
                    placeholder={params.data.id === 'batch-edit' ? 'Tags' : 'Tags'}
                    isMulti={true}
                    className="text-sm"
                />
            </div>
        )
    },
    {
        headerName: 'Status',
        field: 'status',
        width: 160,
        cellRenderer: params => (
            <div className="w-full h-full flex items-center">
                <CustomMultiSelect
                    options={createOptions(VALID_STATUS)}
                    value={params.value ? [{ id: params.value, name: params.value }] : []}
                    onChange={(newValue) => handleValueChange(params, newValue[0]?.id || '', 'status')}
                    placeholder={params.data.id === 'batch-edit' ? 'Status' : 'Status'}
                    isMulti={false}
                    className="text-sm"
                />
            </div>
        )
    },
    {
        headerName: 'Description',
        field: 'description',
        width: 200,
        cellRenderer: params => (
            <input
                type="text"
                value={params.value || ''}
                onChange={(e) => handleValueChange(params, e.target.value, 'description')}
                className="w-full bg-transparent border-none focus:outline-none"
                placeholder={params.data.id === 'batch-edit' ? 'Description' : ''}
            />
        )
    }
    ], [availableTags, availableGenres, availableInstruments, soundTypes, licenses, currentPlayingSound, isPlaying]);

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        suppressMovable: true,
        enableRangeSelection: true,
        rangeSelection: true,
        suppressSizeToFit: true
    };

    const validateFile = (file) => {
        if (!file.typeId) {
            setErrorMessage(`Sound type is required for ${file.name}`);
            return false;
        }
        if (!file.license) {
            setErrorMessage(`License is required for ${file.name}`);
            return false;
        }
        // Check for both image and imagePreview
        if (!file.image && !file.imagePreview) {
            setErrorMessage(`Image is required for ${file.name}`);
            return false;
        }
        return true;
    };

    const handleRetry = async (id) => {
        const file = files.find(f => f.id === id);
        if (!validateFile(file)) return;
    
        try {
            setIsUploading(true);
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === id ? { ...f, uploadStatus: 'uploading' } : f
            ));
    
            const updatedFile = await createSound(file);
            
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === id ? {
                    ...f,
                    ...updatedFile,
                    uploadStatus: 'success',
                    file: f.file,
                    preview: f.preview,
                    image: f.image,
                    imagePreview: f.imagePreview || updatedFile.image
                } : f
            ));
        } catch (error) {
            console.error(`Error uploading sound: ${file.name}`, error);
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === id ? { ...f, uploadStatus: 'error' } : f
            ));
            setErrorMessage(`Failed to ${editMode ? 'update' : 'upload'} ${file.name}`);
        } finally {
            setIsUploading(false);
        }
    };
    
    const handleUploadSelected = async () => {
        setUploadCancelled(false);
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedFiles = selectedNodes.map(node => node.data);
        
        const invalidFiles = selectedFiles.filter(file => !validateFile(file));
        if (invalidFiles.length > 0) {
            setFiles(prevFiles => prevFiles.map(f => {
                if (invalidFiles.some(invalid => invalid.id === f.id)) {
                    return { ...f, uploadStatus: 'error' };
                }
                return f;
            }));
    
            gridRef.current.api.refreshCells({
                force: true,
                rowNodes: selectedNodes.filter(node => 
                    invalidFiles.some(invalid => invalid.id === node.data.id)
                )
            });
    
            setErrorMessage('Please fill in all required fields for selected files before uploading');
            return;
        }
    
        setIsUploading(true);
        
        for (const file of selectedFiles) {
            if (uploadCancelled) break;
            
            try {
                setFiles(prevFiles => prevFiles.map(f => 
                    f.id === file.id
                        ? { ...f, uploadStatus: 'uploading' } 
                        : f
                ));
    
                const currentNode = selectedNodes.find(node => node.data.id === file.id);
                if (currentNode) {
                    gridRef.current.api.refreshCells({
                        force: true,
                        rowNodes: [currentNode]
                    });
                }
    
                const result = await createSound(file);
                
                setFiles(prevFiles => prevFiles.map(f => 
                    f.id === file.id 
                        ? { ...f, uploadStatus: 'success' } 
                        : f
                ));
                
                if (currentNode) {
                    gridRef.current.api.refreshCells({
                        force: true,
                        rowNodes: [currentNode]
                    });
                }
                
                setErrorMessage(`Successfully ${editMode ? 'updated' : 'uploaded'} ${file.name}`);
                
                await new Promise(resolve => setTimeout(resolve, 50));
                
            } catch (error) {
                console.error(`Error ${editMode ? 'updating' : 'uploading'} sound: ${file.name}`, error);
                
                setFiles(prevFiles => prevFiles.map(f => 
                    f.id === file.id
                        ? { ...f, uploadStatus: 'error' } 
                        : f
                ));
                
                const currentNode = selectedNodes.find(node => node.data.id === file.id);
                if (currentNode) {
                    gridRef.current.api.refreshCells({
                        force: true,
                        rowNodes: [currentNode]
                    });
                }
                
                setErrorMessage(`Failed to ${editMode ? 'update' : 'upload'} ${file.name}`);
            }
        }
    
        setIsUploading(false);
    };

    return (
        <div className="flex flex-col h-full bg-bg-secondary/30 backdrop-blur-sm rounded-lg shadow-xl">
            <div className="flex-none sticky top-0 z-30 flex justify-between items-center p-4 bg-bg-secondary/90 backdrop-blur-sm rounded-t-lg border-b border-bg-secondary">
                <div className="flex gap-4 items-center">
                    <button
                        onClick={handleUploadSelected}
                        disabled={isUploading}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                            ${isUploading 
                                ? 'bg-gray-600 cursor-not-allowed' 
                                : 'bg-accent-end hover:bg-accent-start/80'}`}
                    >
                        {editMode ? 'Update Selected' : 'Upload Selected'}
                    </button>
                    {isUploading && handleCancelUpload && (
                        <button
                            onClick={() => {
                                setUploadCancelled(true);
                                handleCancelUpload();
                            }}
                            className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-sm font-medium transition-colors duration-200"
                        >
                            Cancel {editMode ? 'Update' : 'Upload'}
                        </button>
                    )}
                </div>
            </div>
            
            <div className="flex-1 min-h-0 ag-theme-material-dark">
                <AgGridReact
                    ref={gridRef}
                    rowData={[batchEditRow, ...files]}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    suppressRowDeselection={false}
                    getRowId={params => params.data.id || params.data.file?.name}
                    isRowSelectable={params => params.data.id !== 'batch-edit'}
                    getRowStyle={getRowStyle}
                    onGridReady={onGridReady}
                    rowHeight={56}
                    headerHeight={48}
                    getRowClass={params => {
                        if (params.data.id === 'batch-edit') return 'batch-edit-row';
                        const isSelected = params.node.isSelected();
                        return isSelected ? 'selected-row' : '';
                    }}
                />
            </div>
        </div>
    );
};

export default BatchUploadTable;