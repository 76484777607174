import React, { useState } from 'react';
import { Pencil } from 'lucide-react';

const EditableAvatar = ({ currentAvatar, onAvatarChange, isEditable, username, uploading }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      onAvatarChange(file);
    }
  };

  return (
    <div className="relative group">
      <img
        src={previewUrl || currentAvatar}
        alt={`${username}'s avatar`}
        className="w-24 h-24 rounded-full mb-4 sm:mb-0 sm:mr-6 object-cover"
      />
      
      {isEditable && (
        <label className="absolute bottom-4 sm:bottom-0 right-0 sm:-right-1 cursor-pointer bg-bg-secondary rounded-full p-2 shadow-lg border border-border-primary group-hover:bg-accent-start transition-colors duration-200">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            disabled={uploading}
          />
          {uploading ? (
            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
          ) : (
            <Pencil className="w-4 h-4" />
          )}
        </label>
      )}
    </div>
  );
};

export default EditableAvatar;