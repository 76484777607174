// hooks/useUploadForm.js
import { useState, useEffect } from 'react';
import { STEPS, convertSharpToFlat, VALID_KEYS } from '../UploadUtils';
import { 
    fetchSoundById, 
    fetchSoundTypes, 
    fetchTags, 
    fetchGenres, 
    fetchInstruments,
    fetchLicenses,
    analyzeAudio
} from '../../../api/APIManager';

export const useUploadForm = (soundId) => {
    const isEditing = !!soundId;
    
    // Form state
    const [step, setStep] = useState(isEditing ? STEPS.REVIEW : STEPS.SOUND_FILE);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [bpm, setBpm] = useState('');
    const [musicalKey, setMusicalKey] = useState('');
    const [status, setStatus] = useState('');
    const [costInCredits, setCostInCredits] = useState('1');
    const [isUploading, setIsUploading] = useState(false);
    const [audioPreview, setAudioPreview] = useState(null);
    const [isLocalPlaying, setIsLocalPlaying] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [isLoading, setIsLoading] = useState(isEditing);
    const [contentOwnership, setContentOwnership] = useState(false);
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);

    // Options state
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState('');

    useEffect(() => {
        const loadData = async () => {
            try {
                const [types, fetchedTags, fetchedGenres, fetchedInstruments, fetchedLicenses] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses()
                ]);

                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);

                if (isEditing) {
                    const soundData = await fetchSoundById(soundId);
                    setInitialData(soundData);
                }
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [soundId, isEditing]);

    const setInitialData = (soundData) => {
        setName(soundData.name);
        setDescription(soundData.description);
        setTypeId(soundData.type.id);
        setBpm(soundData.bpm?.toString() || '');
        setMusicalKey(soundData.key || '');
        setStatus(soundData.status || '');
        setCostInCredits(soundData.cost_in_credits?.toString() || '1');
        setSelectedTags(soundData.tags.map(tag => ({
            id: tag.id,
            name: tag.name
        })));
        setSelectedGenres(soundData.genres.map(genre => ({
            id: genre.id,
            name: genre.name
        })));
        setSelectedInstruments(soundData.instruments.map(instrument => ({
            id: instrument.id,
            name: instrument.name
        })));
        if (soundData.audio_preview) setAudioPreview(soundData.audio_preview);
        if (soundData.image) setImagePreview(soundData.image);
        if (soundData.license) setSelectedLicense(soundData.license?.id || '');
    };

    const handleFileAnalysis = async (selectedFile) => {
        setIsAnalyzing(true);
        try {
            const analysis = await analyzeAudio(selectedFile);
            setBpm(Math.round(analysis.bpm).toString());
            const convertedKey = convertSharpToFlat(analysis.key);
            if (VALID_KEYS.includes(convertedKey)) {
                setMusicalKey(convertedKey);
            }

            updateAnalyzedClassifications(analysis);
        } catch (error) {
            console.error('Error analyzing audio:', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const updateAnalyzedClassifications = (analysis) => {
        // Update genres
        const analyzedGenres = analysis.genres.split(', ')
            .filter(genreName => genreName.trim() !== '')
            .map(genreName => {
                const genre = availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase());
                return genre ? { id: genre.id, name: genre.name } : { name: genreName };
            });
        setSelectedGenres(analyzedGenres);

        // Update instruments
        const analyzedInstruments = analysis.instruments
            .filter(instrumentName => instrumentName.trim() !== '')
            .map(instrumentName => {
                const instrument = availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase());
                return instrument ? { id: instrument.id, name: instrument.name } : { name: instrumentName };
            });
        setSelectedInstruments(analyzedInstruments);

        // Update tags/moods
        const analyzedTags = analysis.moods.split(', ')
            .filter(moodName => moodName.trim() !== '')
            .map(moodName => {
                const tag = availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase());
                return tag ? { id: tag.id, name: tag.name } : { name: moodName };
            });
        setSelectedTags(analyzedTags);
    };

    return {
        formState: {
            step,
            setStep,
            file,
            setFile,
            image,
            setImage,
            imagePreview,
            setImagePreview,
            name,
            setName,
            fileName,
            setFileName,
            description,
            setDescription,
            typeId,
            setTypeId,
            bpm,
            setBpm,
            musicalKey, 
            setMusicalKey, 
            status,
            setStatus,
            costInCredits,
            setCostInCredits,
            isUploading,
            setIsUploading,
            audioPreview,
            setAudioPreview,
            isLocalPlaying,
            setIsLocalPlaying,
            isAnalyzing,
            isLoading,
            contentOwnership,
            setContentOwnership,
            isGeneratingImage,
            setIsGeneratingImage
        },
        optionsState: {
            soundTypes,
            availableTags,
            selectedTags,
            setSelectedTags,
            availableGenres,
            selectedGenres,
            setSelectedGenres,
            availableInstruments,
            selectedInstruments,
            setSelectedInstruments,
            licenses,
            selectedLicense,
            setSelectedLicense
        },
        handleFileAnalysis
    };
};