import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SearchRow } from '../../components/SoundBrowser/SearchRow';
import BatchUploadTable from '../../pages/BatchUpload/BatchUploadTable';
import { 
    fetchSounds, 
    updateSound,
    fetchSoundTypes, 
    fetchTags, 
    fetchGenres, 
    fetchInstruments, 
    fetchLicenses 
} from '../../api/APIManager';
import  Spinner  from '../../components/Spinner';
import { useToast } from '../../context/ToastContext';

const BatchEdit = () => {
    const { showToast } = useToast();
    const [sounds, setSounds] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currentFilters, setCurrentFilters] = useState({});
    const { setCurrentPlayingSound, setIsPlaying, currentPlayingSound, isPlaying } = useOutletContext();
    const pageSize = 50;
    const [searchRowFilters, setSearchRowFilters] = useState({
        key: [],
        instruments: [],
        genres: [],
        tags: [],
        searchText: ''
    });
    const transformApiSound = (sound) => ({
        id: sound.id,
        file: null,
        name: sound.name,
        preview: sound.audio_preview,
        description: sound.description || '',
        tags: sound.tags || [],
        genres: sound.genres || [],
        instruments: sound.instruments || [],
        typeId: sound.type?.id || '',
        bpm: sound.bpm?.toString() || '',
        key: sound.key || '',
        status: sound.status || 'active',
        costInCredits: sound.cost_in_credits?.toString() || '1',
        license: sound.license?.id || '',
        image: null,
        imagePreview: sound.image || null,
        uploadStatus: 'pending'
    });

    const loadSounds = useCallback(async (newPage, filters = {sort_by_likes: true, status: "all"}) => {
        try {
          
            const response = await fetchSounds(newPage, pageSize, filters);
            const transformedSounds = response.items.map(transformApiSound);

            setSounds(prev => {
                if (newPage === 1) {
                    return transformedSounds;
                }
                // Preserve existing sounds' state when loading more
                const existingSounds = prev.slice(0, (newPage - 1) * pageSize);
                return [...existingSounds, ...transformedSounds];
            });
            setPage(newPage);
            setHasMore(response.items.length === pageSize);
            
        } catch (error) {
            console.error("Error loading sounds:", error);
            showToast("Failed to load sounds. Please try again.", "error");
        }
    }, [showToast, pageSize]);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const [
                    types,
                    fetchedTags,
                    fetchedGenres,
                    fetchedInstruments,
                    fetchedLicenses,
                ] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses(),
                ]);

                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);
                
                await loadSounds(1);
                
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [loadSounds]);

    const handleSearch = async (newFilters) => {
        setIsLoading(true);

        // Store the UI filters
        setSearchRowFilters(newFilters);

        const apiFilters = {
            query: newFilters.searchText || "",
            genre_ids: newFilters.genres?.map(genre => genre.id).join(','),
            tag_ids: newFilters.tags?.map(tag => tag.id).join(','),
            instrument_ids: newFilters.instruments?.map(instrument => instrument.id).join(','),
            key: newFilters.key?.[0]?.name,
            sort_by_likes: true
        };

        setCurrentFilters(apiFilters);
        await loadSounds(1, apiFilters);
        setIsLoading(false);
    };

    const handleLoadMore = () => {
        if (hasMore && !isLoading) {
            loadSounds(page + 1, currentFilters);
        }
    };

    const editSound = async (soundData) => {
        try {
            console.log('Updating sound with license:', soundData.license);
    
            const updateData = {
                name: soundData.name,
                description: soundData.description,
                bpm: parseInt(soundData.bpm) || null,
                key: soundData.key,
                cost_in_credits: parseInt(soundData.costInCredits) || 1,
                status: soundData.status,
                type_id: soundData.typeId,
                license: soundData.license,
                tags: soundData.tags,
                genres: soundData.genres,
                instruments: soundData.instruments,
                image: soundData.image,
            };
    
            console.log('Sending update data:', updateData);
    
            const updatedSound = await updateSound(soundData.id, updateData);
            
            console.log('Received updated sound:', updatedSound);
    
            // Only update the specific sound that was edited
            setSounds(prevSounds => 
                prevSounds.map(sound => 
                    sound.id === soundData.id 
                        ? { 
                              ...transformApiSound(updatedSound), 
                              uploadStatus: 'success',
                              // Preserve any local state we need
                              preview: sound.preview,
                              image: sound.image,
                              imagePreview: sound.imagePreview || updatedSound.image
                          }
                        : sound
                )
            );
    
            return {
                ...transformApiSound(updatedSound),
                uploadStatus: 'success'
            };
        } catch (error) {
            console.error('Failed to update sound:', error);
            // Update error status only for the failed sound
            setSounds(prevSounds => 
                prevSounds.map(sound => 
                    sound.id === soundData.id 
                        ? { ...sound, uploadStatus: 'error' }
                        : sound
                )
            );
            throw error;
        }
    };

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            <div className="flex-none px-4 sm:px-8 pt-4 sm:pt-8">
                <h1 className="text-3xl font-bold">Batch Edit Sounds</h1>
                
                <div className="">
                    <SearchRow onSearch={handleSearch} initialFilters={searchRowFilters} />
                </div>
            </div>

            <div className="flex-1 min-h-0 px-4 sm:px-8 pb-4 overflow-hidden">
            {isLoading && page === 1 && (
                    <Spinner />
                )}

                {sounds.length > 0 && (
                    <div className="h-full flex flex-col">
                        <div className="flex-1 min-h-0">
                            <BatchUploadTable
                                files={sounds}
                                setFiles={setSounds}
                                soundTypes={soundTypes}
                                availableTags={availableTags}
                                availableGenres={availableGenres}
                                availableInstruments={availableInstruments}
                                licenses={licenses}
                                createSound={editSound}
                                createTag={(tag) => Promise.resolve(tag)}
                                createGenre={(genre) => Promise.resolve(genre)}
                                createInstrument={(instrument) => Promise.resolve(instrument)}
                                setErrorMessage={(msg) => showToast(msg, "error")}
                                editMode={true}
                                setCurrentPlayingSound={setCurrentPlayingSound}
                                setIsPlaying={setIsPlaying}
                                currentPlayingSound={currentPlayingSound}
                                isPlaying={isPlaying}
                            />
                        </div>

                        {hasMore && (
                            <div className="flex-none mt-4 flex justify-center">
                                <button
                                    onClick={handleLoadMore}
                                    className="px-6 py-3 bg-accent-end rounded-lg hover:bg-accent-start/80 transition-colors"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading...' : 'Load More'}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchEdit;