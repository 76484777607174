import React, { useState, useRef, useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { createSound, updateSound, fetchSoundById, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument, deleteSoundById, fetchLicenses } from '../api/APIManager';
import { CustomMultiSelect } from '../components/CustomMultiSelect';
import SpinnerFull from '../components/SpinnerFull';

const VALID_STATUS = [
    { value: "active", label: "Public" },
    { value: "inactive", label: "Private" }
];

const VALID_KEYS = [
    " ",
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }
    return key;
};

const EditSound = () => {
    const { soundId } = useParams();
    const isEditing = !!soundId;
    const { setCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const navigate = useNavigate();

    // File and Image States
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [currentFileName, setCurrentFileName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [bpm, setBpm] = useState('');
    const [key, setKey] = useState('');
    const [status, setStatus] = useState('');
    const [costInCredits, setCostInCredits] = useState('1');
    const [isUploading, setIsUploading] = useState(false);
    const [audioPreview, setAudioPreview] = useState(null);
    const [isLocalPlaying, setIsLocalPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(isEditing);
    const [contentOwnership, setContentOwnership] = useState(false);

    // Refs
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);

    // Data States
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [analyzedData, setAnalyzedData] = useState(null);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState('');

    const inputClass = "w-full px-4 py-2 rounded-lg border border-gray-600 bg-bg-secondary focus:ring-2 focus:ring-accent-end focus:border-accent-end transition-colors duration-200";
    const sectionClass = "space-y-4 p-6 rounded-lg bg-opacity-50 backdrop-blur-sm";
    const labelClass = "block text-sm font-medium text-text-secondary mb-2";
    const buttonClass = "w-full px-6 py-3 text-white font-medium rounded-lg transition-all duration-200 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-bg-primary focus:ring-accent-end disabled:opacity-50 disabled:cursor-not-allowed";
    const selectClass = "w-full px-4 py-2 rounded-lg border border-gray-600 bg-bg-secondary appearance-none focus:ring-2 focus:ring-accent-end focus:border-accent-end";
    useEffect(() => {
        const loadData = async () => {
            const types = await fetchSoundTypes();
            setSoundTypes(types);
            
            const fetchedTags = await fetchTags();
            setAvailableTags(fetchedTags);
            const fetchedGenres = await fetchGenres();
            setAvailableGenres(fetchedGenres);
            const fetchedInstruments = await fetchInstruments();
            setAvailableInstruments(fetchedInstruments);
            const fetchedLicenses = await fetchLicenses();
            setLicenses(fetchedLicenses);

            if (isEditing) {
                try {
                    const soundData = await fetchSoundById(soundId);
                    setName(soundData.name);
                    setDescription(soundData.description);
                    setTypeId(soundData.type.id);
                    setBpm(soundData.bpm?.toString() || '');
                    setKey(soundData.key || '');
                    setStatus(soundData.status || '');
                    setCostInCredits(soundData.cost_in_credits?.toString() || '1');
                    setCurrentFileName(soundData.file_name?.split('/').pop() || '');
                    setSelectedLicense(soundData.license?.id || '');

                    setSelectedTags(soundData.tags.map(tag => ({
                        id: tag.id,
                        name: tag.name
                    })));
                    setSelectedGenres(soundData.genres.map(genre => ({
                        id: genre.id,
                        name: genre.name
                    })));
                    setSelectedInstruments(soundData.instruments.map(instrument => ({
                        id: instrument.id,
                        name: instrument.name
                    })));

                    if (soundData.audio_preview) {
                        setAudioPreview(soundData.audio_preview);
                    }
                    if (soundData.image) {
                        setImagePreview(soundData.image);
                    }
                } catch (error) {
                    console.error('Error fetching sound data:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadData();

        return () => {
            if (audioPreview) {
                URL.revokeObjectURL(audioPreview);
            }
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [soundId, isEditing]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this sound? This action cannot be undone.')) {
            try {
                await deleteSoundById(soundId);
                navigate('/');
                window.location.reload();
            } catch (error) {
                alert(`Error deleting sound. Please try again. ${error}`);
                console.error('Error deleting sound:', error);
            }
        }
    };

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const fileName = selectedFile.name.split('.').slice(0, -1).join('.');
            setName(fileName);
            const previewUrl = URL.createObjectURL(selectedFile);
            setAudioPreview(previewUrl);
            setIsLocalPlaying(false);

            setIsAnalyzing(true);
            try {
                const analysis = await analyzeAudio(selectedFile);
                setAnalyzedData(analysis);
                setBpm(Math.round(analysis.bpm).toString());
                
                const convertedKey = convertSharpToFlat(analysis.key);
                if (VALID_KEYS.includes(convertedKey)) {
                    setKey(convertedKey);
                }
                
                const analyzedGenres = analysis.genres.split(', ').map(genreName => {
                    const genre = availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase());
                    return genre ? { id: genre.id, name: genre.name } : { name: genreName };
                });
                setSelectedGenres(analyzedGenres);

                const analyzedInstruments = analysis.instruments.map(instrumentName => {
                    const instrument = availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase());
                    return instrument ? { id: instrument.id, name: instrument.name } : { name: instrumentName };
                });
                setSelectedInstruments(analyzedInstruments);

                const analyzedTags = analysis.moods.split(', ').map(moodName => {
                    const tag = availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase());
                    return tag ? { id: tag.id, name: tag.name } : { name: moodName };
                });
                setSelectedTags(analyzedTags);
            } catch (error) {
                console.error('Error analyzing audio:', error);
            } finally {
                setIsAnalyzing(false);
            }
        }
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        if (selectedImage) {
            const previewUrl = URL.createObjectURL(selectedImage);
            setImagePreview(previewUrl);
        }
    };

    const handleTagChange = (newSelectedTags) => {
        setSelectedTags(newSelectedTags.map(tag => ({
            id: tag.id || availableTags.find(t => t.name === tag.name)?.id,
            name: tag.name
        })));
    };

    const handleInstrumentsChange = (newSelectedInstruments) => {
        setSelectedInstruments(newSelectedInstruments.map(instrument => ({
            id: instrument.id || availableInstruments.find(i => i.name === instrument.name)?.id,
            name: instrument.name
        })));
    };

    const handleGenresChange = (newSelectedGenres) => {
        setSelectedGenres(newSelectedGenres.map(genre => ({
            id: genre.id || availableGenres.find(g => g.name === genre.name)?.id,
            name: genre.name
        })));
    };

    const handleTypeChange = (newSelectedTypes) => {
        setTypeId(newSelectedTypes.length > 0 ? newSelectedTypes[0].id : '');
    };

    const togglePlayPause = () => {
        if (audioPreview) {
            if (isLocalPlaying) {
                setGlobalIsPlaying(false);
            } else {
                setCurrentPlayingSound({ id: 'preview', audio_preview: audioPreview, name: name || 'Preview' });
                setGlobalIsPlaying(true);
            }
            setIsLocalPlaying(!isLocalPlaying);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!contentOwnership) {
            alert("You must declare content ownership before uploading.");
            return;
        }
        setIsUploading(true);
        
        try {
            const updatedTags = await Promise.all(selectedTags.map(async (tag) => {
                if (!tag.id) {
                    const newTag = await createTag({ name: tag.name.toLowerCase() });
                    return { id: newTag.id, name: newTag.name };
                }
                return { ...tag, name: tag.name.toLowerCase() };
            }));

            const updatedGenres = await Promise.all(selectedGenres.map(async (genre) => {
                if (!genre.id) {
                    const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                    return { id: newGenre.id, name: newGenre.name };
                }
                return { ...genre, name: genre.name.toLowerCase() };
            }));

            const updatedInstruments = await Promise.all(selectedInstruments.map(async (instrument) => {
                if (!instrument.id) {
                    const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                    return { id: newInstrument.id, name: newInstrument.name };
                }
                return { ...instrument, name: instrument.name.toLowerCase() };
            }));

            const soundData = {
                file,
                fileName,
                image,
                name,
                description,
                tags: updatedTags,
                genres: updatedGenres,
                instruments: updatedInstruments,
                typeId,
                bpm,
                key,
                cost_in_credits: costInCredits,
                status,
                license: selectedLicense,
            };
            
            if (isEditing) {
                await updateSound(soundId, soundData);
            } else {
                await createSound(soundData);
            }
            
            window.location.reload();
        } catch (error) {
            alert(`Error ${isEditing ? 'updating' : 'uploading'} sound. Please try again. ${error}`);
            console.error(`Error ${isEditing ? 'updating' : 'uploading'} sound:`, error);
        } finally {
            setIsUploading(false);
        }
    };

    if (isLoading) {
        return <SpinnerFull />;
    }

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <div className="bg-bg-primary border border-gray-700 rounded-xl shadow-lg">
                <div className="p-6">
                    <h1 className="text-3xl font-bold mb-8 text-center">
                        {isEditing ? 'Edit Your Sound' : 'Upload Your Sound'}
                    </h1>

                    <form onSubmit={handleSubmit} className="space-y-8">
                        {/* Sound File Section - Updated with equal button sizes */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Sound File</h2>
                            {isEditing && currentFileName && !file && (
                                <div className="text-sm text-text-secondary mb-2 italic">
                                    Current file: {currentFileName}
                                </div>
                            )}
                            <div className="flex items-center gap-4">
                                <input
                                    type="file"
                                    id="file"
                                    ref={fileInputRef}
                                    accept=".wav"
                                    onChange={handleFileChange}
                                    className="hidden"
                                    required={!isEditing}
                                />
                                <label
                                    htmlFor="file"
                                    className="w-48 h-12 inline-flex items-center justify-center rounded-lg bg-accent-end hover:bg-accent-end text-white font-medium cursor-pointer transition-colors duration-200"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                    {isEditing ? 'Replace File' : 'Choose File'}
                                </label>
                                <button
                                    type="button"
                                    onClick={togglePlayPause}
                                    className={`w-48 h-12 rounded-lg bg-accent-end hover:bg-accent-end text-white flex items-center justify-center transition-colors duration-200 ${!audioPreview ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={!audioPreview}
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        {isLocalPlaying ? (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        ) : (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        )}
                                    </svg>
                                    {isLocalPlaying ? 'Pause' : 'Play'}
                                </button>
                            </div>

                            {isAnalyzing && (
                                <div className="flex items-center justify-center gap-2 mt-4">
                                    <div className="animate-pulse flex gap-2">
                                        <div className="w-2 h-2 bg-accent-end rounded-full"></div>
                                        <div className="w-2 h-2 bg-accent-end rounded-full animation-delay-200"></div>
                                        <div className="w-2 h-2 bg-accent-end rounded-full animation-delay-400"></div>
                                    </div>
                                    <span className="text-sm font-medium">Analyzing audio...</span>
                                </div>
                            )}
                        </div>

                        {/* Image Section - Updated with left alignment */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Cover Image</h2>
                            <div className="space-y-4">
                                {imagePreview && (
                                    <div className="w-full">
                                        <img 
                                            src={imagePreview} 
                                            alt="Preview" 
                                            className="max-w-full w-48 h-48 rounded-lg object-cover shadow-lg" 
                                        />
                                    </div>
                                )}
                                <div>
                                    <input
                                        type="file"
                                        id="image"
                                        ref={imageInputRef}
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="hidden"
                                    />
                                    <label
                                        htmlFor="image"
                                        className="inline-flex items-center px-6 py-3 rounded-lg bg-accent-end hover:bg-accent-end text-white font-medium cursor-pointer transition-colors duration-200"
                                    >
                                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        {isEditing ? 'Replace Image' : 'Choose Image'}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Details Section */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Sound Details</h2>
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="name" className={labelClass}>Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className={inputClass}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="description" className={labelClass}>Description</label>
                                    <textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        rows="4"
                                        className={`${inputClass} resize-none`}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Categories Section */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Categories</h2>
                            <div className="space-y-4">
                                <CustomMultiSelect
                                    options={soundTypes}
                                    value={typeId ? [soundTypes.find(type => type.id === typeId)] : []}
                                    onChange={handleTypeChange}
                                    placeholder="Select a sound type..."
                                    label="Sound Type"
                                    isMulti={false}
                                    required={true}
                                />
                                <CustomMultiSelect
                                    options={availableGenres}
                                    value={selectedGenres}
                                    onChange={handleGenresChange}
                                    placeholder="Select genres..."
                                    label="Genres"
                                    isMulti={true}
                                />
                                <CustomMultiSelect
                                    options={availableInstruments}
                                    value={selectedInstruments}
                                    onChange={handleInstrumentsChange}
                                    placeholder="Select instruments..."
                                    label="Instruments"
                                    isMulti={true}
                                />
                                <CustomMultiSelect
                                    options={availableTags}
                                    value={selectedTags}
                                    onChange={handleTagChange}
                                    placeholder="Select tags..."
                                    label="Tags"
                                    isMulti={true}
                                />
                            </div>
                        </div>

                        {/* Audio Properties */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Audio Properties</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="bpm" className={labelClass}>BPM</label>
                                    <input
                                        type="number"
                                        id="bpm"
                                        value={bpm}
                                        onChange={(e) => setBpm(e.target.value)}
                                        className={inputClass}
                                        min="0"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="key" className={labelClass}>Key</label>
                                    <select
                                        id="key"
                                        value={key}
                                        onChange={(e) => setKey(e.target.value)}
                                        className={selectClass}
                                    >
                                        {VALID_KEYS.map((validKey) => (
                                            <option key={validKey} value={validKey}>
                                                {validKey}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Publishing Settings */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Publishing Settings</h2>
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="status" className={labelClass}>Visibility</label>
                                    <select
                                        id="status"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        className={selectClass}
                                    >
                                        <option value="">Select visibility</option>
                                        {VALID_STATUS.map((statusOption) => (
                                            <option key={statusOption.value} value={statusOption.value}>
                                                {statusOption.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {status !== 'inactive' && (
                                    <>
                                        <div>
                                            <label htmlFor="license" className={labelClass}>License</label>
                                            <select
                                                id="license"
                                                value={selectedLicense}
                                                onChange={(e) => setSelectedLicense(e.target.value)}
                                                className={selectClass}
                                                required
                                            >
                                                <option value="">Select a license</option>
                                                {licenses.map((license) => (
                                                    <option key={license.id} value={license.id}>
                                                        {license.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="costInCredits" className={labelClass}>
                                                Price in Tokens (1 Token = $0.07)
                                            </label>
                                            <input
                                                type="number"
                                                id="costInCredits"
                                                value={costInCredits}
                                                onChange={(e) => setCostInCredits(e.target.value)}
                                                className={inputClass}
                                                min="0"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Rights Declaration */}
                        <div className={sectionClass}>
                            <h2 className="text-xl font-semibold mb-4">Rights Declaration</h2>
                            <div className="flex items-start gap-3 p-4 rounded-lg bg-bg-secondary">
                                <input
                                    type="checkbox"
                                    id="contentOwnership"
                                    checked={contentOwnership}
                                    onChange={(e) => setContentOwnership(e.target.checked)}
                                    className="mt-1 h-4 w-4 rounded border-gray-300 text-accent-end focus:ring-accent-end"
                                    required
                                />
                                <label htmlFor="contentOwnership" className="text-sm text-text-secondary">
                                    I declare that I own or have rights to the sounds I'm uploading and that they do not infringe on third-party rights.
                                    <a 
                                        href="/content-rights-info" 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="ml-1 text-accent-start hover:underline">
                                        Learn more about content rights
                                    </a>
                                </label>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="space-y-4 pt-4">
                            <button
                                type="submit"
                                disabled={isUploading || !contentOwnership}
                                className={`${buttonClass} bg-gradient-to-r from-accent-end to-accent-end hover:from-accent-end hover:to-accent-end`}
                            >
                                {isUploading ? (
                                    <div className="flex items-center justify-center gap-2">
                                        <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                        </svg>
                                        <span>{isEditing ? 'Updating...' : 'Uploading...'}</span>
                                    </div>
                                ) : (
                                    <span>{isEditing ? 'Update Sound' : 'Upload Sound'}</span>
                                )}
                            </button>

                            {isEditing && (
                                <button
                                    type="button"
                                    onClick={handleDelete}
                                    className={`${buttonClass} bg-red-600 hover:bg-red-700`}
                                >
                                    Delete Sound
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            {/* Bottom spacing */}
            <div className="h-24" />
        </div>
    );
};

export default EditSound;