import React, { useState, useEffect, useMemo, useRef } from 'react';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import { fetchGenres, fetchInstruments } from '../../api/APIManager';

const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

export const SearchRow = ({ onSearch, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters || {
    key: [],
    instruments: [],
    genres: [],
    searchText: ''
  });

  const [availableGenres, setAvailableGenres] = useState([]);
  const [availableInstruments, setAvailableInstruments] = useState([]);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedGenres, fetchedInstruments] = await Promise.all([
        fetchGenres(),
        fetchInstruments()
      ]);
      setAvailableGenres(fetchedGenres);
      setAvailableInstruments(fetchedInstruments);
    };
    loadData();
  }, []);

  const handleFilterChange = (filterName, value) => {
    const newFilters = {
      ...filters,
      [filterName]: value
    };
    setFilters(newFilters);
    
    if (filterName === 'key') {
      onSearch(newFilters);
    }
  };

  const handlePillClick = (type, item, event) => {
    if (!isDragging) {
      toggleSelection(type, item);
    }
  };

  const toggleSelection = (type, item) => {
    setFilters(prevFilters => {
      const currentSelection = prevFilters[type] || [];
      const isSelected = currentSelection.some(selected => selected.id === item.id);
      
      const newFilters = {
        ...prevFilters,
        [type]: isSelected 
          ? currentSelection.filter(selected => selected.id !== item.id)
          : [...currentSelection, item]
      };
      
      onSearch(newFilters);
      return newFilters;
    });
  };

  const handleClearAll = () => {
    const clearedFilters = {
      key: [],
      instruments: [],
      genres: [],
      searchText: ''
    };
    setFilters(clearedFilters);
    onSearch(clearedFilters);
  };

  const isSelected = (type, item) => {
    return filters[type]?.some(selected => selected.id === item.id);
  };

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const keyOptions = VALID_KEYS.map((key, index) => ({ id: index.toString(), name: key }));

  const sortItemsBySelection = (items, type) => {
    return [...items].sort((a, b) => {
      const aSelected = isSelected(type, a);
      const bSelected = isSelected(type, b);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  };

  const PillList = ({ items, type }) => {
    const scrollRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const sortedItems = useMemo(() => sortItemsBySelection(items, type), [items, filters[type]]);
    
    const handleTouchStart = (e) => {
      setIsDragging(false);
      setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
      if (!startX) return;
      
      setIsDragging(true);
      const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 2;
      scrollRef.current.scrollLeft = scrollLeft - walk;
      e.preventDefault();
    };

    const handleTouchEnd = () => {
      setStartX(null);
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    };
    
    return (
      <div 
  ref={scrollRef}
  className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-text-primary/10 scrollbar-track-transparent touch-pan-x scrollable-content"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="inline-flex flex-col w-full gap-2 min-w-full" style={{ height: '4.5rem' }}>
          <div className="flex flex-nowrap gap-2">
            {sortedItems.slice(0, Math.ceil(sortedItems.length / 2)).map(item => (
              <button
                key={item.id}
                onClick={(e) => handlePillClick(type, item, e)}
                className={`shrink-0 px-3 py-1 rounded-full text-xs transition-colors duration-200 whitespace-nowrap select-none ${
                  isSelected(type, item)
                    ? 'bg-accent-end text-text-primary'
                    : 'border border-text-primary/15 text-text-primary hover:bg-accent-end/10'
                }`}
              >
                {item.name}
              </button>
            ))}
          </div>
          <div className="flex flex-nowrap gap-2">
            {sortedItems.slice(Math.ceil(sortedItems.length / 2)).map(item => (
              <button
                key={item.id}
                onClick={(e) => handlePillClick(type, item, e)}
                className={`shrink-0 px-3 py-1 rounded-full text-xs transition-colors duration-200 whitespace-nowrap select-none ${
                  isSelected(type, item)
                    ? 'bg-accent-end text-text-primary'
                    : 'border border-text-primary/15 text-text-primary hover:bg-accent-end/10'
                }`}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full bg-transparent rounded-lg space-y-3 sm:space-y-4">
      {/* Search Row */}
      <div className="flex flex-col gap-2 sm:gap-4">
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          {/* Search Input */}
          <div className="relative flex-1 order-1">
            <input
              type="text"
              placeholder="Search..."
              className="w-full bg-transparent border border-text-primary/15 rounded-full h-8 pl-8 pr-3 text-xs text-text-primary placeholder-text-primary/70 focus:outline-none focus:ring-1 focus:ring-text-primary"
              onChange={(e) => handleFilterChange('searchText', e.target.value)}
              onKeyPress={handleKeyPress}
              value={filters.searchText}
            />
            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
              <SearchIcon />
            </div>
          </div>

          {/* Key Selection */}
          <div className="w-full sm:w-28 order-2">
            <CustomMultiSelect
              options={keyOptions}
              value={filters.key}
              onChange={(selected) => handleFilterChange('key', selected)}
              placeholder="Key"
              isMulti={false}
            />
          </div>

          {/* Clear & Search Buttons */}
          <div className="flex gap-2 shrink-0 order-3">
            <button
              className="flex-1 sm:flex-none h-8 px-4 rounded-full text-xs font-medium border border-text-primary/15 text-text-primary hover:bg-accent-end/10 transition-duration-200"
              onClick={handleClearAll}
            >
              Clear All
            </button>
            <button
              className="flex-1 sm:flex-none h-8 px-6 rounded-full text-xs font-medium bg-accent-end text-text-primary hover:opacity-90 transition-duration-200"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        {/* Mobile Toggle */}
        <div className="order-4 sm:hidden">
          <button
            className="w-full py-2 text-text-primary text-xs border border-text-primary/15 rounded-full"
            onClick={() => setShowMobileFilters(!showMobileFilters)}
          >
            {showMobileFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>
      </div>

      {/* Filters Section */}
      <div className={`space-y-3 sm:space-y-4 ${showMobileFilters ? 'block' : 'hidden sm:block'}`}>
        {/* Instruments Section */}
        <div className="space-y-2">
          <h3 className="text-xs font-medium text-text-primary">Instruments</h3>
          <div className="w-full">
            <PillList items={availableInstruments} type="instruments" />
          </div>
        </div>

        {/* Genres Section */}
        <div className="space-y-2">
          <h3 className="text-xs font-medium text-text-primary">Genres</h3>
          <div className="w-full">
            <PillList items={availableGenres} type="genres" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRow;