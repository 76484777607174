import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastProvider, useToast } from './context/ToastContext';
import { initializeToast } from './api/APIManager';
import Layout from './components/Layout';
import Index from './pages/Landing';
import Home from './pages/Explore';
import PurchasedSounds from './pages/PurchasedSounds';
import Upload from './pages/Upload/Upload';
import EditSound from './pages/EditSound';
import BatchUpload from './pages/BatchUpload/BatchUpload';
import BatchEdit from './pages/BatchUpload/BatchEdit';
import Likes from './pages/Likes';
import Uploaded from './pages/Uploaded';
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './pages/UserProfile/UserProfile';
import { InstrumentsManager, TagsManager, GenresManager } from './pages/MetaDataManager/MetadataPages';
import AnalyticsComponent from './api/Analytics/Analytics';
import './App.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const ToastInitializer = ({ children }) => {
  const { showToast } = useToast();
  
  useEffect(() => {
    initializeToast(showToast);
  }, [showToast]);

  return <>{children}</>;
};

const AppRoutes = () => {
  useEffect(() => {
    // Prevent zoom
    const preventZoom = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.type === 'wheel') {
        e.preventDefault();
      }
      if (e.scale !== undefined && e.scale !== 1) {
        e.preventDefault();
      }
    };

    // Prevent Safari back/forward swipe
    const preventSwipeNavigation = () => {
      window.history.pushState(null, null, window.location.pathname);
    };
    
    // Initial push state
    preventSwipeNavigation();

    // Add event listeners
    document.addEventListener('wheel', preventZoom, { passive: false });
    document.addEventListener('gesturestart', preventZoom, { passive: false });
    window.addEventListener('popstate', preventSwipeNavigation);

    return () => {
      document.removeEventListener('wheel', preventZoom);
      document.removeEventListener('gesturestart', preventZoom);
      window.removeEventListener('popstate', preventSwipeNavigation);
    };
  }, []);

  return (
    <Router>
      <AnalyticsComponent />
      <Routes>
        <Route path="/landing" element={<Index />} />
        <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route index element={<Home />} />
          <Route path="/purchased" element={<PurchasedSounds />} />
          <Route path="/uploaded" element={<Uploaded />} />
          <Route path="/likes" element={<Likes />} />
          <Route path="upload" element={<Upload />} />
          <Route path="sound/:soundId" element={<EditSound />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
          <Route path="/batch-upload" element={<BatchUpload />} />
          <Route path="/batch-edit" element={<BatchEdit />} />
          <Route path="/admin/tags" element={<TagsManager />} />
          <Route path="/admin/instruments" element={<InstrumentsManager />} />
          <Route path="/admin/genres" element={<GenresManager />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

function App() {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId="400025157675-9qd00tfa32nc2opvolc1mg5sotecp241.apps.googleusercontent.com">
        <ToastProvider>
          <ToastInitializer>
            <AppRoutes />
          </ToastInitializer>
        </ToastProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}

export default App;