// components/Upload.jsx
import React, { useRef } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { STEPS } from './UploadUtils';
import { generateAiImage } from '../../api/APIManager';
import { useUploadForm } from './hooks/UseUploadForm';
import { useUploadHandlers } from './hooks/UseUploadHandlers';
import { StepContainer, SoundFileStep, ImageStep, VisibilityStep, MetadataStep, ClassificationsStep, ReviewStep } from './UploadSteps';
import SpinnerFull from '../../components/SpinnerFull';
import UploadProgressBar from './UploadProgressBar';

const Upload = () => {
    const { soundId } = useParams();
    const isEditing = !!soundId;
    const { setCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const navigate = useNavigate();

    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const dropZoneRef = useRef(null);

    const { formState, optionsState, handleFileAnalysis } = useUploadForm(soundId);
    const { handleFileSelection, handleGenerateAiImage, togglePlayPause, handleSubmit, handleStepNavigation } = 
    useUploadHandlers({
        soundId, // Add this line
        formState,
        optionsState,
        handleFileAnalysis,
        setCurrentPlayingSound,
        setGlobalIsPlaying,
        navigate
    });

    if (formState.isLoading) {
        return <SpinnerFull />;
    }

    return (
        <div className="p-4 sm:p-8 max-w-2xl mx-auto mt-6">
            <div className="relative">
                <div className="absolute top-0 left-1/4 w-64 h-64 bg-accent-end opacity-20 rounded-full blur-3xl -z-10 animate-pulse" />
                <div className="absolute top-1/4 right-1/4 w-64 h-64 bg-accent-end opacity-20 rounded-full blur-3xl -z-10 animate-pulse" />
                
                <motion.h1 
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-4xl font-bold mb-8 text-text-primary"
                >
                    {isEditing ? 'Edit Your Sound' : 'Upload Your Sound'}
                </motion.h1>

                {!isEditing && <UploadProgressBar step={formState.step} handleStepNavigation={handleStepNavigation} isAnalyzing={formState.isAnalyzing} />}
                
                <AnimatePresence mode="wait">
                    <StepContainer key={formState.step}>
                        {formState.step === STEPS.SOUND_FILE && (
                            <SoundFileStep
    fileInputRef={fileInputRef}
    dropZoneRef={dropZoneRef}
    handleFileSelection={handleFileSelection}
    selectedFile={formState.file}  // Add this line
/>
                        )}
                        {formState.step === STEPS.IMAGE && (
                            <ImageStep
                                imageInputRef={imageInputRef}
                                imagePreview={formState.imagePreview}
                                setImage={formState.setImage}
                                setImagePreview={formState.setImagePreview}
                                onPrev={handleStepNavigation.prev}
                                onNext={handleStepNavigation.next}
                                name={formState.name}
                                isGeneratingImage={formState.isGeneratingImage}
                                onGenerateAiImage={handleGenerateAiImage}
                            />
                        )}
                        {formState.step === STEPS.VISIBILITY && (
                            <VisibilityStep {...formState} {...optionsState} onPrev={handleStepNavigation.prev} onNext={handleStepNavigation.next} />
                        )}
                        {formState.step === STEPS.METADATA && (
                            <MetadataStep {...formState} onPrev={handleStepNavigation.prev} onNext={handleStepNavigation.next} />
                        )}
                        {formState.step === STEPS.CLASSIFICATIONS && (
                            <ClassificationsStep {...formState} {...optionsState} onPrev={handleStepNavigation.prev} onNext={handleStepNavigation.next} />
                        )}
                        {formState.step === STEPS.REVIEW && (
                            <ReviewStep
                                {...formState}
                                {...optionsState}
                                togglePlayPause={togglePlayPause}
                                onPrev={handleStepNavigation.prev}
                                onSubmit={handleSubmit}
                            />
                        )}
                    </StepContainer>
                </AnimatePresence>
            </div>
            <div className="h-48" />
        </div>
    );
};

export default Upload;