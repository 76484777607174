import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { JUCESend, JUCEReceive, isJUCE } from '../../context/JUCE';
import { getDownloadURL } from '../../api/APIManager';
import SpinnerFull from '../SpinnerFull';

const PlayIcon = () => (
  <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

const VolumeIcon = ({ volume }) => {
  if (volume === 0) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3.63 3.63a1 1 0 011.41 0L12 10.59V4a1 1 0 011.4-.92l6.83 3.41a1 1 0 010 1.82L14 11.66l7.29 7.29a1 1 0 01-1.41 1.41L3.63 5.05a1 1 0 010-1.42z"/>
      </svg>
    );
  }
  if (volume < 50) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 4L6 9H2v6h4l6 5V4zm4 2.5v11c1.38-1.27 2.25-3.11 2.25-5.17S17.38 7.77 16 6.5z"/>
      </svg>
    );
  }
  return (
    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 4L6 9H2v6h4l6 5V4zm4 2.5v11c1.38-1.27 2.25-3.11 2.25-5.17S17.38 7.77 16 6.5zm4-2.5v16c2.76-2.54 4.5-6.17 4.5-10S22.76 4.54 20 2z"/>
    </svg>
  );
};

const AudioPlayer = ({ sound, isPlaying, onPlayPause, onEnded }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const lastJUCEPosition = useRef(0);
  const [volume, setVolume] = useState(100);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
      if (eventName === 'audioPlayerPosition') {
        lastJUCEPosition.current = eventData;
        
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(eventData);
          const newTime = eventData * duration;
          setCurrentTime(newTime);
        }
      }
      if (eventName === 'audioPlayerDuration') {
        setDuration(eventData);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    let timeUpdateInterval;
    if (isJUCE() && isPlaying) {
      timeUpdateInterval = setInterval(() => {
        setCurrentTime(prevTime => {
          return lastJUCEPosition.current * duration;
        });
      }, 100);
    }

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
      if (timeUpdateInterval) {
        clearInterval(timeUpdateInterval);
      }
    };
  }, [duration, isPlaying]);

  useEffect(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error("Error while destroying WaveSurfer:", error);
      }
    }
  
    const initWaveSurfer = () => {
      if (!sound || !sound.audio_preview) return;
  
      const primaryColorElement = document.createElement('div');
      primaryColorElement.className = 'text-text-primary hidden';
      document.body.appendChild(primaryColorElement);
      const secondaryColorElement = document.createElement('div');
      secondaryColorElement.className = 'text-text-secondary hidden';
      document.body.appendChild(secondaryColorElement);
  
      const primaryColor = getComputedStyle(primaryColorElement).color;
      const secondaryColor = getComputedStyle(secondaryColorElement).color;
  
      document.body.removeChild(primaryColorElement);
      document.body.removeChild(secondaryColorElement);
  
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: secondaryColor,
        progressColor: primaryColor,
        height: window.innerWidth < 640 ? 48 : 64,
        responsive: true,
        barWidth: 2,
        cursorWidth: 1,
        hideScrollbar: true,
        backend: 'MediaElement',
        fillParent: true,
      });
  
      const urlWithEncodedHash = sound.audio_preview.replace(/#/g, '%23');
      waveSurferRef.current.load(urlWithEncodedHash);
  
      waveSurferRef.current.on('ready', () => {
        const waveSurferDuration = waveSurferRef.current.getDuration();
        setDuration(waveSurferDuration);
        if (isJUCE()) {
          JUCESend('audioPlayerDuration', waveSurferDuration);
        }
        setIsWaveSurferReady(true);
        if (isPlaying && !isJUCE()) waveSurferRef.current.play();
        waveSurferRef.current.setVolume(volume / 100);
      });
  
      waveSurferRef.current.on('audioprocess', () => {
        if (!isJUCE()) {
          setCurrentTime(waveSurferRef.current.getCurrentTime());
        }
      });
  
      waveSurferRef.current.on('finish', onEnded);
    };
  
    const timer = setTimeout(initWaveSurfer, 100);
  
    return () => {
      clearTimeout(timer);
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer on cleanup:", error);
        }
      }
    };
  }, [sound, onEnded, isPlaying, volume]);

  useEffect(() => {
    if (isWaveSurferReady && !isJUCE() && waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [isPlaying, isWaveSurferReady]);

  useEffect(() => {
    if (waveSurferRef.current && !isJUCE()) {
      try {
        waveSurferRef.current.setVolume(volume / 100);
      } catch (error) {
        console.error("Error while setting volume:", error);
      }
    }
  }, [volume]);

  const handleSeek = (e) => {
    if (!waveSurferRef.current) return;
    
    const seekPosition = e.nativeEvent.offsetX / e.target.clientWidth;
    
    if (isJUCE()) {
      JUCESend('audioPlayerSeek', seekPosition);
      setCurrentTime(seekPosition * duration);
      lastJUCEPosition.current = seekPosition;
    } else {
      waveSurferRef.current.seekTo(seekPosition);
      setCurrentTime(seekPosition * duration);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    setVolume(newVolume);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDragStart = async (e) => {
    if (isJUCE() && sound && sound.is_purchased) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        JUCESend('dragExport', sound);
      }
    }
  };

  const playerHeight = sound && (isPlaying || isWaveSurferReady) ? 
    (window.innerWidth < 640 ? '96px' : '126px') : '0px';
  const bottomPosition = (isWaveSurferReady && isJUCE()) ? '56px' : '0px';
  const progressPercentage = (currentTime / duration) * 100 || 0;

  if (!sound) {
    return null;
  }

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 z-10 transition-all duration-300 ease-in-out"
      style={{ transform: `translateY(-${bottomPosition})` }}
    >
      <div className="fixed bottom-0 left-0 right-0 h-1 bg-white/5 z-50 overflow-hidden" style={{ bottom: playerHeight }}>
        <div 
          className="h-full w-full bg-gradient-to-r from-accent-start to-accent-end"
          style={{ 
            transform: `translateX(${progressPercentage - 100}%)`,
            transition: 'transform 100ms linear'
          }}
        />
      </div>

      <div 
        className="absolute inset-0 backdrop-blur-md bg-gradient-to-t from-bg-secondary to-bg-secondary/90"
        style={{ height: playerHeight }}
      />
      
      <div 
        className="relative px-4 sm:px-6 py-3 sm:py-4 border-t border-white/10 shadow-lg"
        style={{ height: playerHeight }}
      >
        <div className="flex items-center space-x-3 sm:space-x-4 w-full"> {/* Removed max-w-7xl and added w-full */}
          <div className="relative">
            <div className="absolute inset-0 bg-black/20 rounded blur-md transform translate-y-1"></div>
            <img 
              src={sound.image || sound.image2 || '/default-sound-image.jpg'} 
              alt={sound.name} 
              className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded relative z-10"
            />
          </div>

          <div className="flex-grow min-w-0">
            <div className="text-xs sm:text-sm font-semibold text-text-primary truncate mb-1 sm:mb-2 drop-shadow-sm">
              {sound.name}
            </div>

            <div className="flex items-center space-x-3 w-full">
              <button
                onClick={onPlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-1.5 sm:p-2 rounded-full hover:opacity-90 transition-all duration-200 transform hover:scale-105 shadow-lg flex items-center justify-center flex-shrink-0"
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>

              <div 
                ref={waveformRef} 
                className="flex-grow w-full h-[48px] sm:h-[64px] cursor-pointer hover:opacity-95 transition-opacity duration-200"
                onClick={handleSeek}
                draggable={isJUCE() && sound.is_purchased}
                onDragStart={handleDragStart}
              />

              {!isJUCE() && (
                <div className="flex items-center space-x-2 flex-shrink-0">
                  <VolumeIcon volume={volume} />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="w-20 h-1 rounded-lg appearance-none cursor-pointer bg-white/20"
                  />
                </div>
              )}

              <div className="text-xs text-text-secondary whitespace-nowrap font-medium flex-shrink-0">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;