import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { createSound, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument, fetchLicenses } from '../../api/APIManager';
import { useToast } from '../../context/ToastContext';
import BatchUploadTable from './BatchUploadTable';
import { Upload } from 'lucide-react';

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }
    return key;
};

const generateUniqueId = () => `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const BatchUpload = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const [files, setFiles] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const uploadCancelRef = useRef(false);
    const { setCurrentPlayingSound, setIsPlaying, currentPlayingSound, isPlaying } = useOutletContext();

    useEffect(() => {
        const loadData = async () => {
            try {
                const [types, fetchedTags, fetchedGenres, fetchedInstruments, fetchedLicenses] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses(),
                ]);
                
                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
            }
        };
        loadData();
    }, [showToast]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.types.includes('Files')) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        
        if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
            setIsDragging(false);
        }
    };

    const handleFileDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        let droppedFiles = [];
        if (e.dataTransfer.items) {
            droppedFiles = Array.from(e.dataTransfer.items)
                .filter(item => item.kind === 'file' && item.type.startsWith('audio/'))
                .map(item => item.getAsFile());
        } else {
            droppedFiles = Array.from(e.dataTransfer.files)
                .filter(file => file.type.startsWith('audio/'));
        }

        if (droppedFiles.length === 0) {
            showToast("Please drop WAV audio files only.", "error");
            return;
        }
        await addFiles(droppedFiles);
    };

    const handleFileInput = async (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.target.files).filter(file => file.type.startsWith('audio/'));
        e.target.value = '';
        
        if (selectedFiles.length === 0) {
            showToast("Please select WAV audio files only.", "error");
            return;
        }
        await addFiles(selectedFiles);
    };

    const addFiles = async (newFiles) => {
        const filesWithPreviews = newFiles.map(file => ({
            id: generateUniqueId(),
            file,
            name: file.name.split('.').slice(0, -1).join('.'),
            preview: URL.createObjectURL(file),
            description: '',
            tags: [],
            genres: [],
            instruments: [],
            typeId: '',
            bpm: '',
            key: '',
            status: 'active',
            costInCredits: '1',
            license: '',
            isAnalyzed: false,
            isAnalyzing: true,
            uploadStatus: 'pending',
            image: null,
            imagePreview: null
        }));

        setFiles(prevFiles => [...prevFiles, ...filesWithPreviews]);

        for (let i = 0; i < filesWithPreviews.length; i++) {
            try {
                const analysis = await analyzeAudio(filesWithPreviews[i].file);
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== filesWithPreviews[i].id) return file;
                    
                    return {
                        ...file,
                        bpm: Math.round(analysis.bpm).toString(),
                        key: convertSharpToFlat(analysis.key),
                        genres: analysis.genres.split(', ')
                            .filter(genreName => genreName.trim() !== '')
                            .map(genreName => ({
                                name: genreName,
                                id: availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase())?.id
                            })),
                        instruments: analysis.instruments
                            .filter(instrumentName => instrumentName.trim() !== '')
                            .map(instrumentName => ({
                                name: instrumentName,
                                id: availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase())?.id
                            })),
                        tags: analysis.moods.split(', ')
                            .filter(moodName => moodName.trim() !== '')
                            .map(moodName => ({
                                name: moodName,
                                id: availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase())?.id
                            })),
                        isAnalyzed: true,
                        isAnalyzing: false
                    };
                }));
            } catch (error) {
                console.error(`Error analyzing file ${filesWithPreviews[i].name}:`, error);
                showToast(`Failed to analyze ${filesWithPreviews[i].name}`, "error");
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== filesWithPreviews[i].id) return file;
                    
                    return {
                        ...file,
                        isAnalyzed: false,
                        isAnalyzing: false,
                        uploadStatus: 'error',
                    };
                }));
            }
        }
    };

    const handleBrowseClick = (e) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleCancelUpload = () => {
        uploadCancelRef.current = true;
        setIsUploading(false);
    };

    const handleUploadSound = async (file) => {
        if (uploadCancelRef.current) return null;

        try {
            const updatedTags = await Promise.all(file.tags.map(async (tag) => {
                if (!tag.id) {
                    const newTag = await createTag({ name: tag.name.toLowerCase() });
                    return { id: newTag.id, name: newTag.name };
                }
                return { ...tag, name: tag.name.toLowerCase() };
            }));

            const updatedGenres = await Promise.all(file.genres.map(async (genre) => {
                if (!genre.id) {
                    const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                    return { id: newGenre.id, name: newGenre.name };
                }
                return { ...genre, name: genre.name.toLowerCase() };
            }));

            const updatedInstruments = await Promise.all(file.instruments.map(async (instrument) => {
                if (!instrument.id) {
                    const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                    return { id: newInstrument.id, name: newInstrument.name };
                }
                return { ...instrument, name: instrument.name.toLowerCase() };
            }));

            const result = await createSound({
                ...file,
                tags: updatedTags,
                genres: updatedGenres,
                instruments: updatedInstruments,
                image: file.image,
            });

            return result;
        } catch (error) {
            console.error(`Error uploading sound: ${file.name}`, error);
            throw error;
        }
    };

    const handleUpload = async (file) => {
        // Reset upload cancel ref at the start of new upload
        uploadCancelRef.current = false;
        
        if (!file.typeId || !file.license) {
            showToast(`Please fill in required fields for ${file.name}`, "error");
            // Return error status and update file status
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            return { success: false };
        }

        try {
            // Set upload status to uploading
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === file.id ? { ...f, uploadStatus: 'uploading' } : f
            ));

            const result = await handleUploadSound(file);
            
            if (!result) {
                throw new Error('Upload failed or was cancelled');
            }

            // Only update status to success if we have a result and upload wasn't cancelled
            if (!uploadCancelRef.current) {
                setFiles(prevFiles => prevFiles.map(f => 
                    f.id === file.id ? { ...f, uploadStatus: 'success' } : f
                ));
                showToast(`Successfully uploaded ${file.name}`, "success");
                return { success: true, result };
            } else {
                // Handle cancelled state
                setFiles(prevFiles => prevFiles.map(f => 
                    f.id === file.id ? { ...f, uploadStatus: 'cancelled' } : f
                ));
                return { success: false, cancelled: true };
            }
        } catch (error) {
            // Ensure error status is set and error message is shown
            console.error(`Error uploading ${file.name}:`, error);
            setFiles(prevFiles => prevFiles.map(f => 
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            showToast(`Failed to upload ${file.name}: ${error.message}`, "error");
            throw error;
            return { success: false, error };
        }
    };

    return (
        <div className="flex flex-col h-[calc(100vh-100px)] overflow-hidden">
            <div className="flex-none p-4 sm:p-8">
                <h1 className="text-3xl font-bold">Batch Upload Sounds</h1>
            </div>
            <div className="flex-1 min-h-0 px-4 sm:px-8 overflow-hidden flex flex-col gap-6">
                {files.length === 0 ? (
                    <div 
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        className={`
                            flex-1
                            relative
                            border-2 border-dashed rounded-lg
                            transition-all duration-300 ease-in-out
                            flex flex-col items-center justify-center
                            ${isDragging 
                                ? 'border-accent-start bg-accent-start/10 scale-[1.02]' 
                                : 'border-gray-300 hover:border-accent-start hover:bg-accent-start/5'
                            }
                        `}
                    >
                        <Upload 
                            className={`w-16 h-16 mb-4 transition-all duration-300 ${
                                isDragging ? 'text-accent-start scale-110' : 'text-gray-400'
                            }`}
                        />
                        <div className="space-y-2 text-center">
                            <p className="text-xl font-medium">
                                {isDragging ? 'Drop your files here' : 'Drag & drop your WAV files'}
                            </p>
                            <button
                                onClick={handleBrowseClick}
                                className="text-sm text-accent-start hover:underline focus:outline-none"
                            >
                                or click here to browse
                            </button>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileInput}
                            multiple
                            accept="audio/wav"
                            className="hidden"
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    <div className="h-[calc(100vh-250px)]">
                        <BatchUploadTable
                            files={files}
                            setFiles={setFiles}
                            soundTypes={soundTypes}
                            availableTags={availableTags}
                            availableGenres={availableGenres}
                            availableInstruments={availableInstruments}
                            licenses={licenses}
                            createSound={handleUpload}
                            createTag={createTag}
                            createGenre={createGenre}
                            createInstrument={createInstrument}
                            setErrorMessage={(msg) => showToast(msg, "error")}
                            editMode={false}
                            handleCancelUpload={handleCancelUpload}
                            setCurrentPlayingSound={setCurrentPlayingSound}
                            setIsPlaying={setIsPlaying}
                            currentPlayingSound={currentPlayingSound}
                            isPlaying={isPlaying}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchUpload;