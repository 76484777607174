import React, { useState, useEffect, useContext } from 'react';
import { SoundCard } from './SoundCard';
import { isJUCE, JUCESend, JUCEReceive } from '../../context/JUCE';
import { getDownloadURL } from '../../api/APIManager';
import SpinnerFull from '../../components/SpinnerFull';
import { AuthContext } from '../../context/AuthContext';


const SoundListHeader = () => {
  return (
    <div className="flex items-center h-10 px-2 border-b border-white/5">
      {/* Space for artwork/play button - matches the w-12 + mr-3 from SoundCard */}
      <div className="w-12 mr-3" />
      
      {/* Main grid container - matches SoundCard's grid layout */}
      <div className="flex-grow grid grid-cols-4 md:grid-cols-6">
        {/* Track info column */}
        <div className="col-span-4 md:col-span-2">
          <span className="text-xs font-medium text-text-secondary">NAME</span>
        </div>
        
        {/* Hidden on mobile, shown on desktop */}
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">INSTRUMENTS</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">TYPE</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">KEY</span>
        </div>
        
        <div className="hidden md:block">
          <span className="text-xs font-medium text-text-secondary">BPM</span>
        </div>
      </div>

      {/* Space for action buttons - approximates the width of like, license, download/get, and menu buttons */}
      <div className="flex-shrink-0 w-48 md:w-64" />
    </div>
  );
};

export const SoundList = ({ sounds, onLike, onUnlike, onPurchase, onPlay, onDownload, currentPlayingSound, isPlaying }) => {
  const [purchasingSoundId, setPurchasingSoundId] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const { refreshUserData } = useContext(AuthContext);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, []);

  const handlePurchase = async (soundId) => {
    setPurchasingSoundId(soundId);
    try {
      await onPurchase(soundId);
      await refreshUserData();  // Refresh user data after successful purchase
    } catch (error) {
      console.error('Error purchasing sound:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setPurchasingSoundId(null);
    }
  };
  
  const handleDragStart = async (e, sound) => {
    if (isJUCE()) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        JUCESend('dragExport', sound);
      }
    }
  };

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div>
      <SoundListHeader />
      <div className="space-y-2">
        {sounds.map((sound) => (
          <SoundCard 
            key={sound.id}
            sound={sound} 
            onLike={onLike}
            onUnlike={onUnlike}
            onPurchase={() => handlePurchase(sound.id)}
            onPlay={onPlay}
            onDownload={onDownload}
            currentPlayingSound={currentPlayingSound}
            isPlaying={isPlaying}
            isPurchasing={purchasingSoundId === sound.id}
            onDragStart={handleDragStart}
          />
        ))}
      </div>
    </div>
  );
};

export default SoundList;