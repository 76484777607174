import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from './Logo';
import { CashBadge, TokenBadge } from '../assets/Icons';
import { DEFAULT_USER_IMAGE } from '../api/APIConfig';
import { CustomPopover } from './CustomComponents';
import PricingModal from './PricingSection';

const Header = ({ onMenuToggle }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
  };

  const handleProfileNavigation = () => {
    navigate('/profile/me');
  };

  const cashBalance = (Number(String(user.usd_balance || 0).replace(/,/g, '')) / 100)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-bg-primary z-30 border-b border-white border-opacity-10 h-16 sm:h-20 transition-all duration-300">
        <div className="flex justify-between items-center w-full px-3 sm:px-6 h-full">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <Logo />
          </Link>

          {/* Right side content */}
          <div className="flex items-center space-x-2 sm:space-x-4">
            {/* Balance indicators - Stack vertically on mobile */}
            <div className="hidden sm:flex items-center space-x-4">
              <div className="flex items-center gap-3 font-semibold">
                <button 
                  onClick={handleProfileNavigation}
                  className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer"
                >
                  <span className="flex items-center">
                    {user.credits || 0}
                    <TokenBadge />
                  </span>
                </button>
                <button 
                  onClick={handleProfileNavigation}
                  className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer"
                >
                  <span className="flex items-center">
                    {cashBalance}
                    <CashBadge />
                  </span>
                </button>
              </div>
            </div>

            {/* Mobile-optimized balance display */}
            <div className="flex sm:hidden flex-col items-end text-sm">
              <button 
                onClick={handleProfileNavigation}
                className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer mb-0.5"
              >
                <span className="flex items-center">
                  {user.credits || 0}
                  <TokenBadge />
                </span>
              </button>
              <button 
                onClick={handleProfileNavigation}
                className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer"
              >
                <span className="flex items-center">
                  {cashBalance}
                  <CashBadge />
                </span>
              </button>
            </div>

            {/* User menu and avatar */}
            <div className="flex items-center space-x-2 sm:space-x-4">
              <CustomPopover
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                trigger={
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="focus:outline-none"
                  >
                    <img 
                      src={user.avatar || DEFAULT_USER_IMAGE} 
                      alt="User Avatar" 
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full object-cover"
                    />
                  </button>
                }
                content={
                  <div className="w-40 shadow-2xl border border-white/10 rounded-lg overflow-hidden">
                    <Link
                      to="/profile/me"
                      onClick={() => setIsDropdownOpen(false)}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors duration-200"
                    >
                      Account
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors duration-200"
                    >
                      Logout
                    </button>
                  </div>
                }
              />

              {/* Upgrade button */}
              {(Number(user.credits?.replace(/,/g, '')) || 0) === 0 && (
                <button
                  onClick={() => setIsPricingOpen(true)}
                  className="bg-gradient-to-r from-accent-start to-accent-end text-white px-3 py-1.5 sm:px-4 sm:py-2 text-sm sm:text-base rounded hover:opacity-90 hidden sm:block"
                >
                  Upgrade
                </button>
              )}

              {/* Mobile menu toggle */}
              <button
                className="sm:hidden p-1.5"
                onClick={onMenuToggle}
                aria-label="Toggle menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" y1="12" x2="21" y2="12"></line>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
    </>
  );
};

export default Header;