//Analytics.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AnalyticsService from './AnalyticsService';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    AnalyticsService.init();
  }, []);

  useEffect(() => {
    AnalyticsService.trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default Analytics;