import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';

class AnalyticsService {
  constructor() {
    this.gaInitialized = false;
    this.fbInitialized = false;
    this.amplitudeInitialized = false;
    this.GA_MEASUREMENT_ID = 'G-32PX44JPT8';
    this.FB_PIXEL_ID = '123456789';
    this.AMPLITUDE_API_KEY = '5d93efd4ef70104e397debb6e3a85c1f';
    this.isDebug = false;
    this.currentAmplitudeUserId = null;
  }

  init() {
    this.debugLog('Initializing Analytics Services');
    
    // Set debug mode flags before GTM loads
    // window.gtag_debug = { enable_debug: true };
    
    if (!this.gaInitialized || !this.fbInitialized || !this.amplitudeInitialized) {
      this.initializeGA();
      this.initializeFB();
      this.initializeAmplitude();
    }
  }

  debugLog(message, data = {}) {
    if (this.isDebug) {
      console.log(`[Analytics Debug] ${message}`, data);
    }
  }

  initializeGA() {
    if (this.gaInitialized) return;

    this.debugLog('Initializing Google Analytics');

    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${this.GA_MEASUREMENT_ID}`;
    
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      
      // Configure with debug mode
      gtag('config', '${this.GA_MEASUREMENT_ID}', {
        debug_mode: true,
        send_page_view: false
      });
    `;
    
    document.head.appendChild(script1);
    document.head.appendChild(script2);
    this.gaInitialized = true;

    // Verify gtag is available
    setTimeout(() => {
      if (window.gtag) {
        this.debugLog('Google Analytics initialized successfully');
        // Send a test event
        this.trackEvent('analytics_initialized', {
          success: true,
          timestamp: new Date().toISOString(),
          environment: 'development'
        });
      } else {
        this.debugLog('Warning: gtag not found after initialization');
      }
    }, 2000);
  }

  initializeFB() {
    if (this.fbInitialized) return;

    this.debugLog('Initializing Facebook Pixel');

    window.fbq = window.fbq || function() {
      (window.fbq.q = window.fbq.q || []).push(arguments);
    };
    window._fbq = window._fbq || window.fbq;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.FB_PIXEL_ID}&ev=PageView&noscript=1" />`;
    
    document.head.appendChild(script);
    document.body.appendChild(noscript);

    window.fbq('init', this.FB_PIXEL_ID);
    window.fbq('track', 'PageView');
    
    this.fbInitialized = true;
  }

  initializeAmplitude() {
    if (this.amplitudeInitialized) return;

    this.debugLog('Initializing Amplitude');

    try {
      amplitude.init(this.AMPLITUDE_API_KEY, {
        // Disable autocapture to prevent duplicate events
        autocapture: false,
        // Use consistent device ID across sessions
        deviceId: localStorage.getItem('amp_device_id'),
        // Optional: configure other settings as needed
        trackingOptions: {
          ipAddress: false,
          language: true,
          platform: true
        }
      });

      // Store device ID for consistency
      const deviceId = amplitude.getDeviceId();
      if (deviceId) {
        localStorage.setItem('amp_device_id', deviceId);
      }

      this.amplitudeInitialized = true;
      this.debugLog('Amplitude initialized successfully');
    } catch (error) {
      this.debugLog('Error initializing Amplitude', { error });
    }
  }

  setUser({ userId, userEmail, username }) {
    this.debugLog('Setting user properties', { userId, userEmail, username });

    if (window.gtag) {
      // Configure with user ID
      window.gtag('config', this.GA_MEASUREMENT_ID, {
        user_id: userId
      });

      // Set user properties
      window.gtag('set', 'user_properties', {
        user_id: userId,
        email: userEmail,
        username: username,
        user_type: 'registered',
        environment: 'development'
      });

      // Send a verification event
      this.trackEvent('user_properties_set', {
        user_id: userId,
        email: userEmail,
        username: username,
        timestamp: new Date().toISOString(),
        environment: 'development'
      });
    } else {
      this.debugLog('Warning: gtag not available when setting user');
    }

    if (window.fbq) {
      window.fbq('init', this.FB_PIXEL_ID, {
        external_id: userId,
        em: userEmail
      });
    }

    if (this.amplitudeInitialized) {
      // Only update Amplitude user if it's different
      if (this.currentAmplitudeUserId !== userId) {
        // Reset before setting new user to ensure clean state
        if (this.currentAmplitudeUserId) {
          amplitude.reset();
        }
        
        amplitude.setUserId(userId);
        this.currentAmplitudeUserId = userId;
        
        // Create and configure an Identify object
        const identify = new Identify();
        identify.set('email', userEmail);
        identify.set('username', username);
        identify.set('user_type', 'registered');
        identify.set('environment', 'development');
        identify.set('last_identified', new Date().toISOString());
        
        // Send identify call to Amplitude
        amplitude.identify(identify);
      }
    }
  }

  trackPageView(path) {
    this.debugLog('Tracking page view', { path });

    if (window.gtag) {
      window.gtag('config', this.GA_MEASUREMENT_ID, {
        page_path: path,
        page_location: window.location.href,
        page_title: document.title,
        send_page_view: true
      });
    }

    if (window.fbq) {
      window.fbq('track', 'PageView', {
        path: path
      });
    }

    if (this.amplitudeInitialized) {
      amplitude.track('Page View', {
        path: path,
        page_location: window.location.href,
        page_title: document.title,
        device_id: amplitude.getDeviceId()
      });
    }
  }

  trackEvent(eventName, params = {}) {
    this.debugLog('Tracking event', { eventName, params });

    if (window.gtag) {
      // Add common parameters
      const enhancedParams = {
        ...params,
        timestamp: new Date().toISOString(),
        page_location: window.location.href,
        page_title: document.title,
        environment: 'development'
      };

      window.gtag('event', eventName, enhancedParams);
    }

    if (window.fbq) {
      window.fbq('trackCustom', eventName, params);
    }

    if (this.amplitudeInitialized) {
      amplitude.track(eventName, {
        ...params,
        page_location: window.location.href,
        page_title: document.title,
        environment: 'development',
        device_id: amplitude.getDeviceId()
      });
    }
  }

  trackSignIn(method, userData) {
    this.debugLog('Tracking sign in', { method, userData });

    const params = {
      method,
      ...userData,
      event_category: 'authentication',
      event_label: method,
      timestamp: new Date().toISOString(),
      environment: 'development'
    };

    if (window.gtag) {
      // Send standard login event
      window.gtag('event', 'login', params);
      
      // Send custom event for better tracking
      window.gtag('event', 'user_signed_in', params);
    }

    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration', params);
    }

    if (this.amplitudeInitialized) {
      amplitude.track('Sign In', {
        ...params,
        device_id: amplitude.getDeviceId()
      });
    }
  }

  trackSignOut(userId) {
    this.debugLog('Tracking sign out', { userId });

    const params = {
      user_id: userId,
      event_category: 'authentication',
      event_label: 'logout',
      timestamp: new Date().toISOString(),
      environment: 'development'
    };

    if (window.gtag) {
      window.gtag('event', 'sign_out', params);
    }

    if (window.fbq) {
      window.fbq('trackCustom', 'UserLogout', params);
    }

    if (this.amplitudeInitialized) {
      amplitude.track('Sign Out', {
        ...params,
        device_id: amplitude.getDeviceId()
      });
    }
  }

  clearUser() {
    this.debugLog('Clearing user data');

    if (window.gtag) {
      window.gtag('config', this.GA_MEASUREMENT_ID, {
        user_id: undefined
      });

      // Clear user properties
      window.gtag('set', 'user_properties', {
        user_id: undefined,
        email: undefined,
        username: undefined,
        user_type: undefined
      });
    }

    if (window.fbq) {
      window.fbq('consent', 'revoke');
    }

    if (this.amplitudeInitialized) {
      this.currentAmplitudeUserId = null;
      amplitude.reset();
    }
  }
}

export const Analytics = new AnalyticsService();
export default Analytics;