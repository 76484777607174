import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const DropdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
);

export const CustomMultiSelect = ({ options, value, onChange, placeholder, isMulti = true, required = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0, direction: 'down' });
    
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const selectedItemsRef = useRef(null);

    const safeValue = Array.isArray(value) ? value : [];
    const DROPDOWN_HEIGHT = 240;
    const MARGIN = 8;

    useEffect(() => {
        if (selectedItemsRef.current) {
            const container = selectedItemsRef.current;
            container.scrollLeft = container.scrollWidth;
        }
    }, [safeValue]);

    useEffect(() => {
        if (isOpen && containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - rect.bottom;
            const spaceAbove = rect.top;
            const direction = spaceBelow < DROPDOWN_HEIGHT && spaceAbove > spaceBelow ? 'up' : 'down';

            setDropdownPosition({
                top: direction === 'up' 
                    ? rect.top + window.scrollY - DROPDOWN_HEIGHT - MARGIN
                    : rect.bottom + window.scrollY + MARGIN,
                left: rect.left + window.scrollX,
                width: rect.width,
                direction
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (isOpen && containerRef.current) {
                setIsOpen(false);
            }
        };

        const handleResize = () => {
            if (isOpen && containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const spaceBelow = viewportHeight - rect.bottom;
                const spaceAbove = rect.top;
                const direction = spaceBelow < DROPDOWN_HEIGHT && spaceAbove > spaceBelow ? 'up' : 'down';

                setDropdownPosition({
                    top: direction === 'up' 
                        ? rect.top + window.scrollY - DROPDOWN_HEIGHT - MARGIN
                        : rect.bottom + window.scrollY + MARGIN,
                    left: rect.left + window.scrollX,
                    width: rect.width,
                    direction
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current && 
                !dropdownRef.current.contains(event.target) &&
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            setSearchTerm('');
            setHighlightedIndex(-1);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isOpen]);

    const filteredOptions = options.filter(option =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
        const aSelected = safeValue.some(v => v.id === a.id);
        const bSelected = safeValue.some(v => v.id === b.id);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
    });

    const toggleOption = (option) => {
        if (isMulti) {
            const newValue = safeValue.some(v => v.id === option.id)
                ? safeValue.filter(v => v.id !== option.id)
                : [...safeValue, option];
            onChange(newValue);
        } else {
            onChange([option]);
        }
        // Close the dropdown after every selection
        setIsOpen(false);
        setSearchTerm('');
        setHighlightedIndex(-1);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const removeOption = (optionToRemove, event) => {
        event.stopPropagation();
        const newValue = safeValue.filter(v => v.id !== optionToRemove.id);
        onChange(newValue);
    };

    const renderSelectedItems = () => {
        if (safeValue.length === 0) {
            return <span className="text-gray-400 text-sm">{placeholder}</span>;
        }

        return (
            <div 
                ref={selectedItemsRef}
                className="flex items-center space-x-1 overflow-x-auto hide-scrollbar"
                style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
            >
                {safeValue.map(item => (
                    <span 
                        key={item.id}
                        className="flex-shrink-0 bg-white/20 text-white rounded-full px-1.5 py-0.5 text-xs whitespace-nowrap flex items-center"
                    >
                        {item.name}
                        <button
                            className="ml-1 text-white hover:text-red-500 focus:outline-none"
                            onClick={(e) => removeOption(item, e)}
                        >
                            ×
                        </button>
                    </span>
                ))}
            </div>
        );
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && filteredOptions.length > 0 && highlightedIndex >= 0) {
            e.preventDefault();
            toggleOption(filteredOptions[highlightedIndex]);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : 0
            );
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        }
    };

    const renderDropdown = () => {
        if (!isOpen) return null;

        const dropdown = (
            <div 
                ref={dropdownRef}
                className="fixed bg-bg-secondary border border-text-primary/30 rounded-md shadow-lg overflow-hidden"
                style={{
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                    width: `${dropdownPosition.width}px`,
                    zIndex: 9999
                }}
            >
                <div className="relative">
                    <input
                        ref={inputRef}
                        type="text"
                        className="w-full p-1.5 pl-7 text-xs border-0 focus:outline-none focus:ring-0 bg-bg-secondary text-text-primary placeholder-text-secondary"
                        placeholder={`Search ${placeholder.toLowerCase()}...`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onClick={(e) => e.stopPropagation()}
                    />
                    <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                        <SearchIcon />
                    </div>
                </div>
                <div className="max-h-48 overflow-y-auto">
                    {filteredOptions.map((option, index) => (
                        <div
                            key={option.id}
                            className={`
                                p-1.5 cursor-pointer text-xs
                                transition-colors duration-150 ease-in-out
                                ${safeValue.some(v => v.id === option.id) ? 'bg-white/15 text-white' : 'text-text-primary'}
                                ${index === highlightedIndex ? 'bg-white/30' : 'hover:bg-black/50 hover:text-white'}
                            `}
                            onClick={() => toggleOption(option)}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
            </div>
        );

        return createPortal(dropdown, document.body);
    };

    return (
        <div 
            className="relative w-full" 
            ref={containerRef}
        >
            <div
                className={`
                    bg-bg-secondary border border-white/30 hover:border-accent-end 
                    rounded-full px-3 py-1.5 cursor-pointer h-7 flex items-center justify-between 
                    text-xs transition-all duration-300 ease-in-out
                    ${isOpen ? 'ring-1 ring-bg-white/50' : ''}
                `}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex-grow overflow-hidden">
                    {renderSelectedItems()}
                </div>
                <div className="flex-shrink-0 ml-2">
                    <DropdownIcon />
                </div>
            </div>
            {renderDropdown()}
        </div>
    );
};

export default CustomMultiSelect;